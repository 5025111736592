import React, { useEffect, useState } from 'react'; 
import '../../css/observations.css';
import {useNavigate} from 'react-router-dom'; 
import { fetch_all_dashboards_list, add_observation_data } from '../../../../services/observations/Service';
import useDashboard from '../../../../Hooks/useDashboard';
import PageDescription from '../../components/PageDescription';
import { LoaderLoader } from '../../../utils/CommonLibrary';


const AddObservation = ({paths={observations: '/observations'}}) => {
  const dashboardData = useDashboard();
  

  const [isRequestLoading, setIsrequestLoading] = useState(false); 
  const navigate = useNavigate(); 
  const [observationForm, setObservationForm]  = useState({ 
    observationType: '',
    observationTitle: '',
    dashboard: (dashboardData.value == 11 || dashboardData.value == 4) ?"": dashboardData.value, 
    priority: '', 
    files: [], 
    description: ''
  }); 
  const [descriptionText, setDescriptionText] = useState("Choose an observation type, set priority, and add a clear description. Attach any helpful screenshots or logs. More details mean faster resolutions!"); 

  const [observationFormErrors, setObservationFormErrors] = useState({
    observationType: '',
    observationTitle: '',
    dashboard: '', 
    priority: '',
    files: '', 
    description: ''
  }); 

  const [loader, setLoader] = useState(false); 

  const [dashboards, setDashboards]  = useState([]);
  const [error, setError] = useState(null);

  // Update the file input state to include a unique identifier
  const [fileInputs, setFileInputs] = useState([{ id: Date.now() }]);

  // Update the addFileInput function to use timestamp as unique ID
  const addFileInput = () => {
    setFileInputs([...fileInputs, { id: Date.now() }]);
  };

  // Update the removeFileInput function
  const removeFileInput = (inputId) => {
    if (fileInputs.length > 1) {
        // Keep existing multiple input removal logic
        setFileInputs(fileInputs.filter(input => input.id !== inputId));
        
        const updatedFiles = [...observationForm.files];
        const inputIndex = fileInputs.findIndex(input => input.id === inputId);
        if (inputIndex !== -1) {
            updatedFiles.splice(inputIndex, 1);
            setObservationForm({ ...observationForm, files: updatedFiles });
        }
    } else {
        // For single input, clear the file and reset the input
        setObservationForm({ ...observationForm, files: [] });
        
        // Reset the file input element
        const fileInput = document.querySelector(`input[data-input-id="${inputId}"]`);
        if (fileInput) {
            fileInput.value = '';
        }
    }
  };

  // 

  useEffect(() => { 
    if(dashboardData.value == 11 || dashboardData.value == 4){ 
      fetchAllDashboardsList(dashboardData.value); 
    }
  }, []); 


  // submit the Observation 
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Prevent submission if already loading
    if (isRequestLoading) return;
    
    setIsrequestLoading(true);
    const validationErrors = validateForm(observationForm);
    
    if (Object.keys(validationErrors).length > 0) {
      setObservationFormErrors(validationErrors);
      setIsrequestLoading(false);
      return;
    }

    let formData = new FormData();
    
    for (const file of observationForm.files) {
      formData.append('files[]', file); 
    }      
    
    
    let observationDataCopy = {...observationForm};
    delete observationDataCopy.files;
    
    let observationDataObj  = {...observationDataCopy, dashboard: observationDataCopy.dashboard.split('-')[0], dashboardVersion: (dashboardData.value == 11 || dashboardData.value == 4 )? observationDataCopy.dashboard.split('-')[1] : dashboardData.version, userType: dashboardData.value == 11 ? 'Moderator' : 'User'};

    formData.append('observationData', JSON.stringify(observationDataObj));    
    
    // If file sending formData with request  
    let requestData = observationForm.files.length > 0 ? formData : {observationData: JSON.stringify(observationDataObj)};
    
    // Requesting to Add observation 
    try{ 
      const response = await add_observation_data(requestData);
      if (response.data.code === 200) {
        setObservationForm({
          observationType: '',
          observationTitle: '',
          dashboard: '',
          priority: '',
          files: [],
          description: ''
        });
        
        setObservationFormErrors({
          observationType: '',
          observationTitle: '',
          dashboard: '',
          priority: '',
          files: '',
          description: ''
        });

        navigate(paths.observations);
      } else {
        setError(response.data.data.message);
      }
    }catch (error) {
      setError("Something went wrong");
    }finally {
      setIsrequestLoading(false);
    }    
  }


  function validateForm(formData) {
    const errors = {};
    if (!formData.observationType) {
      errors.observationType = 'Observation Type is required';
    }
    if (!formData.observationTitle) {
      errors.observationTitle = 'Observation Title is required';
    }

    if (formData.observationTitle.length > 120) {
      errors.observationTitle = 'Observation Title must be less than 120 characters';
    }


    if (!formData.dashboard) {  
      errors.dashboard = 'Dashboard is required';
    }

    if (!formData.priority) {
      errors.priority = 'Priority is required';
    }
    


    // validate the files more based on size and type
    if (formData.files) {
      if (formData.files.size > 10000000) {
        errors.files = 'File size is too large. Max size is 10MB';
      }

      const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png','mp4', 'mov', 'avi', 'xlsx', 'doc', 'docx', 'xls', 'txt', 'dcm'];
      Array.from(formData.files).forEach(file => {
        const fileExtension = file.name.split('.').pop().toLowerCase();        
        if (!allowedExtensions.includes(fileExtension)) {
          errors.files = 'Invalid file type. Only PDF, JPEG, JPG, PNG, TIFF, DOC, DOCX, XLS, XLSX, CSV, TXT, and DCM files are allowed';
        }
      });

    }

    if (!formData.description) {
      errors.description = 'Description is required';
    }

    return errors;
  }


  // handle change 
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'files[]') {
      const inputId = parseInt(e.target.getAttribute('data-input-id'));
      const inputIndex = fileInputs.findIndex(input => input.id === inputId);
      
      const updatedFiles = [...observationForm.files];
      updatedFiles[inputIndex] = e.target.files[0];
      
      setObservationForm({ ...observationForm, files: updatedFiles.filter(Boolean) });
      setObservationFormErrors({ ...observationFormErrors, files: '' });
      return;
    }

    setObservationForm({ ...observationForm, [name]: value });
    setObservationFormErrors({ ...observationFormErrors, [name]: '' });
  }

  const clearForm =  (e) => { 
    setObservationForm({ 
      observationType: '',
      observationTitle: '',
      dashboard: '', 
      observationDescription: '',
      priority: '', 
      files: [], 
      description: ''
    });

    setObservationFormErrors({
      observationType: '',
      observationTitle: '',
      dashboard: '', 
      observationDescription: '',
      priority: '', 
      files: '', 
      description: ''
    });
  }


  // fetch all dashboards list 
  const fetchAllDashboardsList = async (dashboardId) => {
    try {
      setLoader(true);
      const response = await fetch_all_dashboards_list();
      
      if (response.data.code === 200) {
        if(dashboardId == 11){ 
          setDashboards(response.data.data || []);
        }
        else if(dashboardId == 4){
          let dashboardsToShow = response.data?.data?.filter((dashboard) => (dashboard.id == 4 || dashboard.id == 5));
          setDashboards(dashboardsToShow || []);

        }
      }else { 
        setError(response.data.data.message); 
      }

    }catch(error) {
      setError("Something went wrong"); 
    }finally {
      setLoader(false);
    }
  }

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className="container-fluid observation-dashboard-page">
        <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
            }}>
            <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackClick}><i className="bi bi-chevron-left me-1"></i> Back</button>
            <div className="col"> 
                <h4 className="mb-0 content-main-title">Add New Observation</h4>
            </div>
        </div>      
        </div>
        <LoaderLoader isLoading={loader} />

        <PageDescription image="https://storage.googleapis.com/ksabupatest/2024/11/21/d95l6/s8xox4w1ns.jpg" text={descriptionText} />

        <form onSubmit={e => handleSubmit(e)}>
            <div className='comntent-UserManagement p-3 mb-3'>
                <div className="row">
                    <div className="col-md-7 col-sm-12 px-4">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="observationType" className="form-label">Observation Title</label>
                                <span style={{ color: 'red' }}>&nbsp;*</span>
                                <div className="d-flex">
                                  <input type="text" className="form-control input-field-ui" name='observationTitle' id="observationTitle" placeholder="Enter a concise title summarizing the observation"
                                    value={observationForm.observationTitle} 
                                    onChange={e => handleOnChange(e)}
                                  />
                                </div>
                                <div className='invalid-input'>{observationFormErrors.observationTitle}</div>
                            </div>

                            {
                              (dashboardData.value == 11 || dashboardData.value == 4) && (
                                <div className='col-md-4 mb-3'>
                                  <label htmlFor="dashboard" className="form-label">Dashboard</label> 
                                  <span style={{ color: 'red' }}>&nbsp;*</span>
                                  <div className="d-flex">
                                  <select className="form-select input-field-ui" id="roleId" aria-label="Default select example"
                                  name='dashboard'
                                  value={observationForm.dashboard} 
                                  onChange={e => handleOnChange(e)}
                                  >
                                      <option value="">Select Dashboard</option>
                                      { 
                                        dashboards.map((dashboard) => (
                                          <option value={dashboard.id + '-' + dashboard.version}>{dashboard.dashboardName}</option>
                                        ))
                                      }
                                                                    
                                  </select>
                                </div>
                                <div className='invalid-input'>{observationFormErrors.dashboard}</div>
                              </div>
                            )
                            }


                            <div className="col-md-4 mb-3">
                                <label htmlFor="observationType" className="form-label">Observation Type</label>
                                <span style={{ color: 'red' }}>&nbsp;*</span>
                                <div className="d-flex">
                                  <select className="form-select input-field-ui" id="roleId" aria-label="Default select example" 
                                    name='observationType'
                                    value={observationForm.observationType} 
                                    onChange={e => handleOnChange(e)}
                                  >
                                      <option value="">Select Observation Type</option>
                                      <option value="new_requirement">New Requirement</option>
                                      <option value="issue">Issue</option>
                                      <option value="enhancement_request">Enhancement Request</option>
                                      <option value="suggestion">Suggestion</option>
                                  </select>
                                </div>
                                <div className='invalid-input'>{observationFormErrors.observationType}</div>
                            </div>    

                            <div className="col-md-4 mb-3">
                            
                                <label htmlFor="priority" className="form-label">Priority</label>
                                <span style={{ color: 'red' }}>&nbsp;*</span>
                                <div className="d-flex">
                                  <select className="form-select input-field-ui" id="roleId" aria-label="Default select example"
                                  name='priority'
                                  value={observationForm.priority} 
                                  onChange={e => handleOnChange(e)}
                                  >
                                      <option value="">Select Priority</option>
                                      <option value="blocker">Blocker</option>
                                      <option value="major">Major</option>
                                      <option value="minor">Minor</option>
                                      <option value="good_to_have">Good-to-have</option>                                    
                                  </select>
                                </div>
                                <div className='invalid-input'>{observationFormErrors.priority}</div>
                            </div>

                            <div className="col-md-12 mb-3">
                            
                                <label htmlFor="files" className="form-label"> Attach screenshots or data that illustrate the observation ( The maximum allowed file upload size is 10 MB )</label>
                                {fileInputs.map((input) => {
                                    const hasFile = observationForm.files[fileInputs.findIndex(f => f.id === input.id)];
                                    
                                    return (
                                        <div className="d-flex mb-2" key={input.id}>
                                            <input 
                                                type="file" 
                                                className="form-control input-field-ui form-control-sm" 
                                                name="files[]" 
                                                accept="image/*,video/*" 
                                                data-input-id={input.id}
                                                onChange={e => handleOnChange(e)}
                                            />
                                            {(fileInputs.length > 1 || (fileInputs.length === 1 && hasFile)) && (
                                                <button 
                                                    type="button" 
                                                    className="btn btn-Cancel ms-2" 
                                                    onClick={() => removeFileInput(input.id)}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            )}
                                        </div>
                                    );
                                })}
                                <button 
                                  type="button" 
                                  className="btn btn-primary addNewRow-btn me-2" 
                                  onClick={addFileInput}
                                >
                                  <i className="bi bi-plus"></i>
                                  Add More Files
                                </button>
                                <div className='invalid-input'>{observationFormErrors.files}</div>
                            </div>

                            

                            <div className="col-md-12 mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <span style={{ color: 'red' }}>&nbsp;*</span>
                                
                                <div className="vertical-display">
                                    {/* create text area input here  */}
                                    <textarea 
                                      name='description'
                                      id="description"
                                      className="form-control control-input-ui-addmember"
                                      style={{ resize: 'none' }}
                                      rows="4"
                                      cols={"50"}
                                      placeholder="Describe the observation in detail, including expected behavior and steps to reproduce it."
                                      value={observationForm.description} 
                                      onChange={e => handleOnChange(e)}
                                    />  
                                </div>
                                <div className='invalid-input'>{observationFormErrors.description}</div>
                                
                            </div>

                            <div className="col-md-12 mb-3">  
                                <button 
                                  type="submit" 
                                  className="btn btn-primary btn-primary program-save-btn me-2"
                                  disabled={isRequestLoading}
                                >
                                  {isRequestLoading ? 'Adding...' : 'Add'}
                                </button>                                
                                <button type="reset" className="btn clear-btn" onClick={e => clearForm(e)}>Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>    
  )
}

export default AddObservation
