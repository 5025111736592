import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { fetchApiInsight } from '../components/services/Service';
import axios from 'axios';

const ApiInsight = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRole, setSelectedRole] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [adminUserData, setAdminUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    


useEffect(() => {
    fetchInsightData();
},[]);


// const fetchInsightData = async () => {
//     try {
//         alert("call")
//             // setIsLoading(true); 
//             let response = await fetchApiInsight();
//                 setAdminUserData(response.data.data.logs);
//                 // setIsLoading(false);
//     } catch (error) {
//       console.error(error);
//     }
// };


const fetchInsightData = async () => {
    axios.post(process.env.REACT_APP_BASE_URL + 'sso/fetch_api_insight', {
    }).then(function (response) {
        if (response.data.code === 200) {
            setAdminUserData(response.data.data.logs);
        } else {
        }
    }).catch(function (error) {
    });

}


    const columns = [
        {
            name: 'Membership No',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'API Name',
            selector: row => row.apiName,
            sortable: true,
        },
        {
            name: 'Post Data',
            selector: row => row.postData,
            sortable: true,
        },
        {
            name: 'Response Data',
            selector: row => row.rawResponse,
            sortable: true,
        },
        {
            name: 'Service Type',
            selector: row => row.serviceType,
            sortable: true,
        },
        {
            name: 'Created Time',
            selector: row => row.createdTime,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <span style={{ color: row.status == 'success' ? '#01CD88' : '#FF0000' }}>
                    {row.status}
                </span>
            ),
        },
    ];
    
    const data = adminUserData;


    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedRole('All');
        setSelectedStatus('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery =
            user.membershipNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.apiName.toLowerCase().includes(searchQuery.toLowerCase());


        return matchesSearchQuery;
    });

    return (
        <>
        <div className='d-block m-3 '>
                {/* <LoaderLoader isLoading={isLoading} /> */}
                <div className='row mb-12'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search by Membership No or API Name"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            <br/>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination={false}
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </div>
        </>
    );
}

export default ApiInsight;
