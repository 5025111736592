// import React from 'react';
import React, { useState,useEffect } from "react";
import CryptoJS from 'crypto-js';
import Loader from "react-js-loader";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetch_program_data } from "../../../services/met/Service";




export const encryptDataNew = (text) => {
  try {
      const key_hex = process.env.REACT_APP_KEY;
      const iv_hex = process.env.REACT_APP_IV;

      const key = CryptoJS.enc.Hex.parse(key_hex);
      const iv = CryptoJS.enc.Hex.parse(iv_hex);

      const encrypted = CryptoJS.AES.encrypt(text, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString();
  } catch (error) {
      console.error("encryptData Exception = ", error);
      return false;
  }
};

export const decryptData = (encryptedText) => {
  const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_KEY);
  const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);

  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const decruptCipherJson = (ciphertext) => {
  try{
    var key = 'a1b2c3d4e5f6g7h8';
    var ciphertextWA = CryptoJS.enc.Base64.parse(ciphertext);
    var keyWA = CryptoJS.enc.Utf8.parse(key);
    var ivWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(0, 4));
    var hmacWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4, 4 + 8));
    var actualCiphertextWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4 + 8));
    var hmacCalculatedWA = CryptoJS.HmacSHA256(actualCiphertextWA, keyWA);
    var decryptedMessageWA = CryptoJS.AES.decrypt({ ciphertext: actualCiphertextWA }, keyWA, { iv: ivWA });
    var decryptedMessage = CryptoJS.enc.Utf8.stringify(decryptedMessageWA);

    if(!decryptedMessage){
      // window.location.href = '/logout';
    }
  }catch{
    // window.location.href = '/logout';
  }
  
  return JSON.parse(decryptedMessage);
}

export const encryptCipherJson = (data) => {
  const key = 'a1b2c3d4e5f6g7h8';
  const keyWA = CryptoJS.enc.Utf8.parse(key);
  const ivWA = CryptoJS.lib.WordArray.random(16);
  const jsonString = JSON.stringify(data);
  const encryptedMessage = CryptoJS.AES.encrypt(jsonString, keyWA, { iv: ivWA });
  const hmacWA = CryptoJS.HmacSHA256(encryptedMessage.ciphertext, keyWA);
  const combinedWA = ivWA.concat(hmacWA).concat(encryptedMessage.ciphertext);
  const combinedBase64 = CryptoJS.enc.Base64.stringify(combinedWA);

  return combinedBase64;
}

export const encryptCipherJsonReact = (ciphertext) => {

  var key = 'a1b2c3d4e5f6g7h8';
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(ciphertext), key).toString();
  return ciphertext;
}

export const decruptCipherJsonReact = (ciphertext) => {
  try{
    var key = 'a1b2c3d4e5f6g7h8';
    var bytes  = CryptoJS.AES.decrypt(ciphertext, key);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }catch{
    window.location.href = '/logout';
  }
  return decryptedData;
}

export const decruptCipherString = (ciphertext) => {

  try{
    var key = 'a1b2c3d4e5f6g7h8';
  
    var ciphertextWA = CryptoJS.enc.Base64.parse(ciphertext);
    var keyWA = CryptoJS.enc.Utf8.parse(key);
    var ivWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(0, 4));
    var hmacWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4, 4 + 8));
    var actualCiphertextWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4 + 8));
    var hmacCalculatedWA = CryptoJS.HmacSHA256(actualCiphertextWA, keyWA);
    var decryptedMessageWA = CryptoJS.AES.decrypt({ ciphertext: actualCiphertextWA }, keyWA, { iv: ivWA });
    var decryptedMessage = CryptoJS.enc.Utf8.stringify(decryptedMessageWA);
    
  }catch{
    window.location.href = '/logout';
  }
  
  return decryptedMessage;
}

export const CustomLoader = () => {
  
  return (
      <div className="loading-overlay col-md-12">
          <div className="loader-position">
              <Loader type="spinner-default" bgColor={"#000"} color={'#FFFFFF'} size={100} />
          </div>
      </div>
  )

}

export const SelectProgram = ({ programData, selectedProgram, onProgramChange }) => {
  return (
    <div className="row g-3 align-items-center">
      <div className="col-auto">
        <label htmlFor='SelectProgram' className="col-form-label">Select Program</label>
      </div>
      <div className="col">
        <select 
          className="form-select input-field-ui" 
          id="programId" 
          name="programId" 
          value={selectedProgram} 
          onChange={onProgramChange}
          aria-label="Default select example"
        >
          <option>Select Role</option>
          {programData.map(option => (
            <option key={option.programId} value={option.programId}>
              {option.programName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export const LoaderLoader = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay col-md-12">
    <div className="loader-position">
        <Loader type="spinner-default" bgColor={"#000"} color={'#FFFFFF'} size={100} />
    </div>
</div>
  );
};


export const ModalBox = (props) =>{ 
  
  const showModal= props.showModaln;
  const modalbodyComponent = props.bodyn;
  const title = props.titlen ;
  const type = props.type ;
  // alert(type)
  let cls = '';
  if(type==''  || typeof(type)=='undefined'){
    cls = (title==='Alert') ?'modal-sm': ((title==='Alert!') ? 'modal-md' : 'modal-xl');
  }else{
    cls = (type==='sm') ?'modal-sm': ((type==='md') ? 'modal-md' : 'modal-xl');
  }
 
  return (
      <>
        <Modal dialogClassName={cls} show={showModal} onHide={props.handleClose}>
          <Modal.Header closeButton>
          <Modal.Title 
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "700",
              color: title === "Alert" ? "red" : "black" 
            }}
          >
            {title}
          </Modal.Title>
          </Modal.Header>
          <hr/>
          <Modal.Body >
            {modalbodyComponent}
          </Modal.Body>
        </Modal>
      </>
  );
  
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const Commingsoon = () =>{ 
  
  return(
          <div className="modal fade" id="upcomingFeatureModal" tabindex="1" aria-labelledby="upcomingFeatureModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                  <div className="modal-header mb-4">
                                      <h4 className="modal-subtitle">Available in Phase 2!</h4>
                            <h1 className="modal-title mt-3">Upcoming Feature:</h1>
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/te82r/xsyel27471.png" style={{height:"260px"}} alt="" />
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>
  );

  };

  export const CommingsoonPage = () =>{ 
  
    return(
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header mb-4">
                    <h4 className="modal-subtitle">Available in Phase 2!</h4>
                    <h1 className="modal-title mt-3">Upcoming Feature:</h1>
                    <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/te82r/xsyel27471.png" style={{height:"260px"}} alt="" />
                  </div>
                </div>
            </div>
          );
    };


  export const PathwayDots=({pathway})=> {

 
    return (
        <>
            <div className='DropDownAction'>
            <div class="btn-group dropend">
            <button type="button" class="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-three-dots-vertical"></i>
            </button>
            <ul class="dropdown-menu">
                <li className='list-class'><Link to={`/add-service/${pathway}`} class="dropdown-item"><i class="bi bi-pen"></i> Edit Pathway</Link></li>
                {/* <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-plus-circle"></i> Add Pathway</Link></li> */}
                <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-x-circle text-danger"></i> Delete Pathway</Link></li>
            </ul>
            </div>
            </div>
            <Commingsoon />
        </>
      );
    }

    export const ProgramDots=()=> {

 
      return (
          <>
              <div className='DropDownAction'>
              <div class="btn-group dropend">
              <button type="button" class="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
              </button>
              <ul class="dropdown-menu">
                  <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal"  data-bs-target="#upcomingFeatureModal"><i class="bi bi-pen"></i> Edit Program</Link></li>
                  <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-plus-circle"></i> Add Condition</Link></li>
                  <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-x-circle text-danger"></i> Delete Program</Link></li>
              </ul>
              </div>
              </div>
              <Commingsoon />
          </>
        );
      }

export const Filterdashboard=({ onChange })=> {
  const [programData,setProgramData] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const fetchProgramData = async () => {
    setIsLoading(true); //to show loader
    try {
        let resp = await fetch_program_data();
        if (resp.data.code === 200) {
            setProgramData(resp.data.data);
        }
    } catch (error) {
        console.error(error);
    }finally {
      setIsLoading(false); // Will be called regardless of success or failure
      }
    }
    useEffect(() => {
      fetchProgramData();
  }, []);
 
        return (
            <>
              <div className="col-md-3">
                          {isLoading ? (
                              <div>Loading...</div>
                            ) : (
                                 <select className="form-select input-field-ui" id="SelectProgram" onChange={onChange} aria-label="Default select example">
                                 <option value="">Select Program</option>
                                  {programData.map((option, index) => (
                                    <option key={index} value={option.programId}>
                                      {option.programName}
                                    </option>
                                  ))}
                                  </select>
                                )
                          }
                    </div>
            </>
        );
    }

export const SummeryDashboardData =()=>{


        const stats = [
          { count: 48184, label: 'Invited Members', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/vsksd/k88eveca7u.png' },
          { count: 41801, label: 'Enrolled Members', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/48t4m/30kswhl360.png' },
          { count: 6383, label: 'Not Enrolled', bgColor: 'bg-grey', icon: 'https://storage.googleapis.com/ksabupatest/2024/09/24/8ogjs/whn8x2c8f4.png' },
        ];

        return(
        <div className=" row text-center">
                {stats.map((stat, index) => (
                        <div className="col" key={index}>
                            <div className={`card ${stat.bgColor}`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <img src={stat.icon} alt={`${stat.label} icon`} className="card-icon mb-0" />
                                    </div>
                                    <div className="col px-0">
                                        <h5 className="card-text">{stat.label}</h5>
                                    </div>
                                    <div className="col-auto">
                                        <h1 className="card-title">{stat.count}</h1>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
        );
}


export const formatDateYmd = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const formatDateTime = (date) => {
  if (!date) return "";
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}