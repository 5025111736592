import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import './FormsModal.css';
import MedicationForms from '../MedicationForms/MedicationForms'
import LabRequestForms from '../LabRequestForms/LabRequestForms';
import HomeBasedVaccine from '../HomeBasedVaccine/HomeBasedVaccine';
import HomeCareRequest from '../HomeCareRequest/HomeCareRequest';
import MedicationFormsMetaDoc from '../MedicationForms/MedicationFormsMetaDoc';
import LabRequestMetaDocForms from '../LabRequestForms/LabRequestMetaDocForms';
import HomeCareRequestMetaDoc from '../HomeCareRequest/HomeCareRequestMetaDoc';
import HomeBasedVaccineMetaDoc from '../HomeBasedVaccine/HomeBasedVaccineMetaDoc';


const FormsModal = ({ formShort, header, memberBasicInfo, showHRAModal, handleShowHRAModal, handleCloseHRAModal, selectedServiceRecord, callHomeBasedServicesHistory, selectedBulkServices }) => {

    const closeModal = async (e) => {
        handleCloseHRAModal();
    };

    return (
        <>
            <Modal id="wpnb2" className='HealthManagement' centered show={showHRAModal} onHide={handleCloseHRAModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>{header} {selectedServiceRecord.serviceName != "" && ` - ${selectedServiceRecord.serviceName}`}</h1>
                </Modal.Header>
                {/* <hr/> */}
                <Modal.Body>
                    {/* <Tabs defaultActiveKey="MedicationRequest" id="uncontrolled-tab-example">
                        <Tab eventKey="MedicationRequest" title="Medication Request">
                             <MedicationForms />
                        </Tab>
                        <Tab eventKey="LabRequest" title="Lab Request">
                            <LabRequestForms />
                        </Tab>
                    </Tabs> */}

                    {formShort == 'med' && <MedicationForms closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}
                    {formShort == 'hbl' && <LabRequestForms closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}
                    {formShort == 'hhc' && <HomeCareRequest closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} selectedBulkServices={selectedBulkServices} />}
                    {formShort == 'hbv' && <HomeBasedVaccine closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}

                    {formShort == 'medmetadoc' && <MedicationFormsMetaDoc closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}
                    {formShort == 'hblmetadoc' && <LabRequestMetaDocForms closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}
                    {formShort == 'hhcmetadoc' && <HomeCareRequestMetaDoc closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} selectedBulkServices={selectedBulkServices} />}
                    {formShort == 'hbvmetadoc' && <HomeBasedVaccineMetaDoc closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FormsModal;