import React from 'react'
import { Modal } from 'react-bootstrap'
import { Mic, MicOff, AlertCircle } from 'react-feather'

const MicrohponeCheck = ({showCheckMicrophonePermissionModal, setShowCheckMicrophonePermissionModal}) => {
  return (
    <Modal
          id="checkMicrophonePermissionModal"
          show={showCheckMicrophonePermissionModal}
          onHide={() => setShowCheckMicrophonePermissionModal(false)}
          centered
          size="lg"
          backdrop="static"
          keyboard={false}
          fullscreen={false}
        >
          <Modal.Header closeButton className="border-b pb-4">
            <div className="d-flex items-center gap-3">
              <div className="bg-red-100 p-2 rounded-full">
                <Mic className="h-6 w-6 text-red-600" />
              </div>
              <h1 className="modal-title text-xl font-semibold m-0">Microphone Access Required</h1>
            </div>
          </Modal.Header>
          <Modal.Body className="py-6">
            <div className="text-center px-4">
              <div className="mb-6 bg-gray-50 p-6 rounded-lg">
                <MicOff className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                <p className="text-lg font-medium text-gray-800 mb-2">
                  We can't access your microphone
                </p>
                <p className="text-gray-600 mb-4">
                  To use this feature, we need permission to access your microphone. Please check your browser settings and ensure microphone access is enabled.
                </p>

              </div>

              <div className="mt-4 text-sm text-gray-500 text-center">
                <p>Having trouble? Try these steps:</p>
                <ul className="list-disc pl-6 mt-2 inline-block text-left">
                  <li>Check if your microphone is properly connected</li>
                  <li>Allow microphone access in your browser settings</li>
                  <li>Restart your browser if problems persist</li>
                </ul>
              </div>

              <div className="flex items-center justify-center gap-2 text-sm text-gray-500">
                <AlertCircle className="h-4 w-4" />
                <span>Your privacy is important to us. We only use the microphone when needed.</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
  )
}

export default MicrohponeCheck
