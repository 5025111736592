import React from 'react'; 
import { decruptCipherJson } from '../pages/utils/CommonLibrary';

const useDashboard = () => {
  const dashboard = localStorage.getItem('dashboard') ? localStorage.getItem('dashboard') : '';
  const dashboardData = decruptCipherJson(dashboard);
  return dashboardData;
}

export default useDashboard
