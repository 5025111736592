import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { initWebexCalling, setWebexCall, setWebexLine, setIsIncoming, setCallerInfo, setIsWebexRegistering, setCallDisposition,setCnChatNode } from '../../../store/webexSlice';
import { soft_number_logger } from '../../../services/met/Service';
import { check_webex_enable, updateWebexCallStatus } from '../../cn/components/services/Service';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import DispositionModal from '../../cn/components/Disposition/DispositionModal';




const CnLayout = () => {

    const navigate = useNavigate(); 
    
    const dispatch = useDispatch(); 
    const webex = useSelector(state => state.webex);
    
    const { webexCalling, webexCall, webexTransferCall, webexLine } = webex;
    const [isWebexEnabled, setIsWebexEnabled] = useState(false); 
    const [minTime, setMinTime] = useState(new Date()); 
    const [maxTime, setMaxTime] = useState(new Date()); 

    const [formData, setFormData] = useState({ 
        callType: "", 
        internalNotes: "", 
        externalNotes:"", 
        scheduleFollowupCall: false, 
        callBackDateTime: null,
        callBackNotes: ""
    });

    const [formDataErrors, setFormdataErrors] = useState({
        callType: "",
        internalNotes: "", 
        externalNotes: "", 
        scheduleFollowupCall: false, 
        callBackDateTime: "", 
        callBackNotes: "", 
        submissionError: ""
    });


    useEffect(() => { 
        checkIsWebexEnabled(); 
    }, []);

     // init webex if not already intiialzied 
     useEffect(() => { 
        if(!webex.webexCalling && isWebexEnabled){
            let token = localStorage.getItem('call_client_id'); 
            console.log("following is the value of your token",token); 
            if(!token) { 
                navigate('/logout');
                return; 
            }  

            dispatch(initWebexCalling()); 
        }

        // de-registereing  the device 
        // return () =>{ 
        //     if(webexCalling){ 
        //       webexCalling.deregister();
        //     }
        // }
    }, [isWebexEnabled]);


    // listening for the webex events
    useEffect(() => { 
        if(webex.webexLine){ 

           
           
            // handling call 
            const handleIncommingCall = (call) => {
                dispatch(setWebexCall(call)); 
                dispatch(setIsIncoming(true)); 

                call.on('caller_id', (CallerIdEmitter) => { 
                    dispatch(setCallerInfo(CallerIdEmitter.callerId)); 
                });

                call.on('disconnect', () => { 
                    dispatch(setIsIncoming(false)); 
                }); 

                call.on('call_eror', async (e) => {   
                    let errorInfo = { 
                        code: e.code || null,
                        message: e.message || 'No message available',
                        stack: e.stack || null,
                        details: e.details || null,
                        name: e.name || 'Unknown Error',
                    }; 
                    
                    await soft_number_logger(errorInfo); 

                }); 

                call.on('connect', () => { 
         
                }); 
            }

            webex.webexLine.on('line:incoming_call', handleIncommingCall);

            return () => { 
                webex.webexLine.off('line:incoming_call', handleIncommingCall); 
            }
        }
    }, [webex.webexLine]); 


    
    useEffect(() => {
        const now = new Date();
        const isToday = formData.callBackDateTime instanceof Date && formData.callBackDateTime.toDateString() === now.toDateString();

        let minDate;

        if (isToday) {
            // Set minTime to current time if today
            minDate = new Date();
            minDate.setHours(now.getHours(), now.getMinutes(), 0, 0);
        } else {
            // Set minTime to start of the day for future dates
            minDate = new Date(formData.callBackDateTime);
            minDate.setHours(0, 0, 0, 0);
        }

        if (isNaN(minDate.getTime())) {
            //console.error('Invalid minDate value:', minDate);
            minDate = new Date();
        }
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999);

        // Ensure endOfDay is a valid Date object
        if (isNaN(endOfDay.getTime())) {
            //console.error('Invalid endOfDay value:', endOfDay);
        }

        setMinTime(minDate);
        setMaxTime(endOfDay);

    }, [formData.callBackDateTime]);


    // check if webex is enabled or not 
    function checkIsWebexEnabled(){ 
       dispatch(setIsWebexRegistering(true));
        check_webex_enable().then((response) => {
            setIsWebexEnabled(response.data.data?.webexEnable); 
            dispatch(setCnChatNode(response.data.data?.firebaseNode));                
            if(!response.data.data?.webexEnable){
                dispatch(setIsWebexRegistering(false));                
            }
        }).catch((error) => {
            dispatch(setIsWebexRegistering(false));
        }); 
    }


    function handleChange(e) {
        setFormData({...formData, 
            [e.target.name]: e.target.value
        }); 

        setFormdataErrors({...formDataErrors, 
            [e.target.name]: ""
        });
    }

    function handleScheduleCall(e){ 
        if(e.target.checked){
            setFormData({...formData, scheduleFollowupCall: true});
        }else { 
            setFormData({...formData, scheduleFollowupCall: false});
        }
    }

    function handleSubmit(){ 
        let errors = validateForm(); 
        if(Object.keys(errors).length > 0){
            setFormdataErrors({...formDataErrors, ...errors});
            return; 
        }

        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }



        let data = {...formData, callReasonMain: formData.callType}; 
        data['callConferenceNumber'] = webex.callConferenceNumber; 

        if(!formData.scheduleFollowupCall){ 
            data['callBackDateTime'] = null;
        }


        

        updateWebexCallStatus(data, ct).then((response) => {
            if(response.data.code === 200){
                dispatch(setCallDisposition(false));
                setFormdataErrors({});
            }
            }).catch((error) => {
                console.log(error);
            }); 

    }

    // validate the formData    
    function validateForm() {
        let errors = {};

        if(!formData.callType){
            errors['callType'] = 'Call Type is required';
        }

        if(formData.scheduleFollowupCall){ 
            if(!formData.callBackDateTime){
                errors['callBackDateTime'] = 'Call Back Date & Time is required';
            }
        }


        return errors;
    }

    function addDaystoDate(date, n = 21) {
        let currDate = new Date(date);
        currDate.setDate(currDate.getDate() + n);
        return currDate;
    }

    return (
        <div className='container-fluid'>
            <Outlet isWebexEnabled={isWebexEnabled} />   
            
            
            { 
               webex.callDisposition && <DispositionModal />
            }

        </div>
    )
}

export default CnLayout; 