import React, { useState } from "react";
import moment from "moment";
import MemberCommunicationsTable from '../components/MemberCommunicationsTable';
import CalendarDatePicker from "../../componenents/CalendarDatePicker";
import { decryptData } from "../utils/CommonLibrary";

function ContentMemberCommunicationsDetails() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate());

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <>
            <div className="ContentUserManagement">


                <div className="container-fluid">
                    <div className="row align-items-center pb-3 sticky-top-bar">
                        <div className="col-md">
                            <h4 className="mb-0 content-main-title">Member Communications</h4>
                        </div>
                        <div className="col-md-3">
                            <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                        </div>
                    </div>

                    <div className="row align-items-center pb-3">
                        <div className='reports-programs'>
                            <div className=" row align-items-center">
                                <div className="col-md-auto">
                                    <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/so7jq/qhjo3szoyh.png" alt="Invited Members icon" style={{ height: "140px" }} />
                                </div>
                                <div className="col-md">
                                    <p className="mb-0">The Member Communication function allows you to review all past communications sent to members, perform audits, and find the status of these communications, such as delivery and receipt. This is crucial for maintaining effective and transparent member engagement.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center pb-3">
                        <div className='comntent-UserManagement mb-3'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <MemberCommunicationsTable startDate={startDate} endDate={endDate} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ContentMemberCommunicationsDetails;
