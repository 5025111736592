import common_axios from "./commonAxios";

let baseURL = common_axios.defaults.baseURL + 'observations/';


export const fetch_admin_user_data = () => {
    return common_axios.get(baseURL + 'fetch_admin_user_data')
};

export const hcc_user_creation_data = (data) => {
    return common_axios.post(baseURL + 'hcc_user_creation_data', data)
};

export const fetch_ad_users_list = (searchString) => {
    return common_axios.post(baseURL + 'fetch_ad_users', { searchString })
};


export const fetch_role_data = () => {
    return common_axios.get(baseURL + 'fetch_role_data')
};


export const hcc_fetch_admin_user_data = (userId) => {
    return common_axios.post(baseURL + 'hcc_fetch_admin_user_data', {userId})
};

export const hcc_admin_user_update_data = (userId, data) => {
    return common_axios.post(baseURL + 'hcc_admin_user_update_data', {...data, userId})
};  