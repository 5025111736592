import React, { useState, useEffect, useRef } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import firebase from "../../../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateChatNotifty } from "../../../../services/cn/Service";
import { useUserTabs } from "../common/UserTabsContext";

const NotificationDropdown = () => {
  const cnchatnode = useSelector((state) => state.webex.cnchatnode);
  console.log(cnchatnode, "cnchatnode");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef(null);
  const buttonRef = useRef(null);
  const containerRef = useRef(null);
  const [notifications, setNotifications] = useState([]);

  const { userTabs, addUserTab, closeUserTab } = useUserTabs();
  const handleUserClick = (user) => {
    addUserTab(user);
  };

  // Fetch all notifications at once
  const fetchNotifications = async () => {
    try {
      if (cnchatnode) {
        const database = getDatabase(firebase);
        const notificationsRef = ref(database, cnchatnode);

        onValue(notificationsRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const newNotifications = Object.keys(data).map((key) => ({
              id: key,
              message: data[key]?.message || "No message",
              read: data[key]?.read || false,
              time: data[key]?.time,
              userId: data[key]?.userId,
              name: data[key]?.name, 
            })).sort((a, b) => {
                // Parse the time strings into Date objects for comparison
                const timeA = new Date(a.time);
                const timeB = new Date(b.time);
                return timeB - timeA; // Descending order
            });

            // Update notifications state with all fetched notifications
            setNotifications(newNotifications);
          } else {
            setNotifications([]); // Clear notifications if no data
          }
        });
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchNotifications(); // Fetch notifications when the dropdown opens
    }
  }, [isOpen]);

  useEffect(() => {
    fetchNotifications(); // Fetch notifications when the dropdown opens
  }, [cnchatnode]);

  const formatMessageTime = (messageTime) => {
    const messageDate = new Date(messageTime);
    const now = new Date();
    const timeDiff = now - messageDate;

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) return "Just now";
    if (minutes < 60) return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
    if (hours < 24) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    if (days === 1) return "Yesterday";

    return messageDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const handleNotificationClick = (index, id, name) => {
    setNotifications((prev) =>
      prev.map((notification, i) =>
        i === index ? { ...notification, read: true } : notification
      )
    );
    // navigate(`/members/${id}`);
    handleUserClick({ userId: id, name: name });
    navigate("/members/" + encodeURIComponent(id, "UTF-8"));

    setIsOpen(false);
    updateChatNotifty(id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside both the dropdown and the toggle button
      if (
        containerRef.current && 
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="tw-relative tw-pe-4 tw-ps-3" ref={containerRef}>
      <button
        type="button"
        ref={buttonRef}
        onClick={() =>setIsOpen(!isOpen)}
        className="btn message-icon position-relative tw-border-none"
      >
        <i className="bi bi-whatsapp tw-pb-6"></i>
        {notifications.length > 0 && (
          <span className="tw-absolute tw-top-0 tw-start-[60%] tw-bg-amber-400 tw-animate-bounce tw-rounded-full tw-text-white tw-text-xs tw-px-1 border tw-border-white">{parseFloat(notifications.length)}</span>
        )}
      </button>
      {isOpen && (
        <div className="tw-relative">
        <ul
          ref={listRef}
          className="tw-absolute tw-z-40 tw-top-full tw-right-0 tw-mt-2 tw-bg-gray-300 tw-rounded-lg tw-shadow-2xl tw-p-0 tw-w-80 tw-max-h-[25rem] tw-overflow-y-auto tw-overflow-x-hidden [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:none] [scrollbar-width:none]"
        >
          {notifications.map((data, index) => (
            <div key={index} className="tw-transform-gpu">
              <li
                onClick={() => handleNotificationClick(index, data.userId, data.name)}
                className={`tw-relative tw-px-4 tw-py-4 tw-cursor-pointer hover:tw-bg-gray-100 hover:tw-scale-[1.02] tw-transition-all tw-duration-500 tw-list-none tw-min-h-[4.5rem] border-bottom ${
                  !data.read ? "tw-bg-[#f1f6fc]" : "tw-bg-white"
                }`}
              >
                <div className="tw-pr-16">
                  <span className="tw-text-gray-700 tw-text-sm">
                    {data.message}
                  </span>
                </div>
                <span className="tw-absolute tw-bottom-3 tw-right-4 tw-text-gray-400 tw-text-xs">
                  {formatMessageTime(data.time)}
                </span>
              </li>
            </div>
          ))}
          {notifications.length === 0 && (
            <li className="tw-text-center tw-py-3 tw-text-gray-500 tw-text-sm">
              No notifications
            </li>
          )}
        </ul>
      </div>
      )}
    </div>
  );
};

export default NotificationDropdown;
