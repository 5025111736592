import { createSlice } from "@reduxjs/toolkit";


const paginationSlice = createSlice({
    name: "pagination",
    initialState: {
        observations: { 
            perPage: 10, 
            currentPage: 1, 
            totalEntries: 0, 
            totalPages: 0
        }
    },
    reducers: {
        setObservationsPerPage: (state, action) => { state.observations.perPage = action.payload; },
        setObseravationsCurrentPage: (state, action) => { state.observations.currentPage = action.payload; },
        setObservationsTotalEntries: (state,action) => {state.observations.totalEntries = action.payload;},
        setObservationsTotalPages: (state,action) => {state.observations.totalPages = action.payload;}
    },
});


export const { setObservationsPerPage, setObseravationsCurrentPage, setObservationsTotalEntries, setObservationsTotalPages } = paginationSlice.actions;
export default paginationSlice.reducer;