import React, { useState, useEffect } from 'react';
import './PaginationComponent.css'

const PaginationComponent = ({ totalRecords, fetchData }) => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentRecordsRange, setCurrentRecordsRange] = useState('');

    // Calculate the total pages based on totalRecords and rowsPerPage
    useEffect(() => {
        const pages = Math.ceil(totalRecords / rowsPerPage);
        setTotalPages(pages);
    }, [rowsPerPage, totalRecords]);

    // Update the current records range (e.g., 1-10 of 50)
    useEffect(() => {
        const start = (currentPage - 1) * rowsPerPage + 1;
        const end = Math.min(currentPage * rowsPerPage, totalRecords);
        setCurrentRecordsRange(`${start}-${end}`);
    }, [currentPage, rowsPerPage, totalRecords]);

    // Fetch data whenever currentPage or rowsPerPage changes
    useEffect(() => {
        fetchData(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage, fetchData]);

    // Handlers for page navigation
    const goToFirstPage = () => setCurrentPage(1);
    const goToPreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const goToNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const goToLastPage = () => setCurrentPage(totalPages);

    return (
        <div className="pagination-container">
            {/* Rows per page dropdown */}
            <div className="rows-per-page">
                <label>Rows per page: </label>
                <select value={rowsPerPage} onChange={(e) => setRowsPerPage(Number(e.target.value))}>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                </select>
            </div>

            {/* Display of current records range */}
            <div className="records-info">
                {currentRecordsRange} of {totalRecords}
            </div>

            {/* Pagination controls */}
            <div className="pagination-controls">
                <button onClick={goToFirstPage} disabled={currentPage === 1}>
                    <i class='bx bx-first-page'></i>
                </button>
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                    <i class='bx bx-chevron-left' ></i>
                </button>
                <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                    <i class='bx bx-chevron-right' ></i>
                </button>
                <button onClick={goToLastPage} disabled={currentPage === totalPages}>
                    <i class='bx bx-last-page'></i>
                </button>
            </div>
        </div>
    );
};

export default PaginationComponent;