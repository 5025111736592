import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import MembesAccrossPathwayMonthsBar from "../componenents/DashboardGraphsCNP/MembesAccrossPathwayMonthsBar";
// import MemberPathwayStatusPie from "../componenents/DashboardGraphsCNP/MemberPathwayAssignedStatusPie";
// import CalendarDatePicker from "../componenents/DashboardGraphsCNP/CalendarDatePicker";
import moment from "moment";
// import { encryptData, decryptData } from "../../";
import MembesAccrossPathwayMonthsBar from "../whatsapp/MembesAccrossPathwayMonthsBar";
import MemberPathwayStatusPie from "../componenents/DashboardGraphs/MemberPathwayStatusPie";
import CalendarDatePicker from "../componenents/CalendarDatePicker";
import { encryptData, decryptData } from "../utils/Utils";
import {
  fetch_count,
  fetch_carenavigator,
  fetch_admin_insights,
  fetch_admins,
} from "../../services/whatsapp/Service";
const AnalyticsDashboard = () => {
  const [loading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [seriesGraph, setSeriesGraph] = useState([]);
  const [adminGraph, setAdminGraph] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const [adminsUsers, setAdminusers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); // Optional if provided by API

  //const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [startDate, setStartDate] = useState(moment());

  const [endDate, setEndDate] = useState(moment());

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key) {
      direction = sortConfig.direction === "asc" ? "desc" : "asc";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <i className="fa fa-sort tw-ml-2 tw-text-gray-400"></i>;
    }
    return sortConfig.direction === "asc" ? (
      <i className="fa fa-sort-up tw-ml-2"></i>
    ) : (
      <i className="fa fa-sort-down tw-ml-2"></i>
    );
  };

  const getSortedData = (data) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Get sorted data
  const sortedData = getSortedData(adminsUsers);

  // Add this handler function
  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Mock the localStorage for the date range picker
  useEffect(() => {
    const mockStoredDates = [
      moment().subtract(7, "days"),
      moment(),
      moment().startOf("year"),
      moment(),
    ];
    localStorage.setItem(
      "selectedDates",
      encryptData(JSON.stringify(mockStoredDates))
    );
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const fetchData = async (start, end) => {
    try {
      const response = await fetch_count({ startDate: start, endDate: end }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data.insights.length > 0) {
        setAnalyticsData(response.data.data.insights);
      }
      // Assuming the API returns an array of data objects that you need for your dashboard
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_carenavigator_insights = async (start, end) => {
    try {
      const response = await fetch_carenavigator({
        startDate: start,
        endDate: end,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setSeriesGraph(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_admin_insights_data = async (start, end) => {
    try {
      const response = await fetch_admin_insights({
        startDate: start,
        endDate: end,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setAdminGraph(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_admins_data = async (start, end, page = 1, limit = 10) => {
    try {
      const response = await fetch_admins({
        startDate: start,
        endDate: end,
        page: page,
        limit: limit,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setAdminusers(response.data.data.list);
        setTotalRecords(response.data.data.total); // Assuming response includes `total`
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(formatDate(startDate), formatDate(endDate));
  }, [startDate, endDate]); // Trigger when either startDate or endDate changes

  useEffect(() => {
    fetch_carenavigator_insights(formatDate(startDate), formatDate(endDate));
  }, [startDate, endDate]); // Trigger when either startDate or endDate changes

  useEffect(() => {
    fetch_admin_insights_data(formatDate(startDate), formatDate(endDate));
  }, [startDate, endDate]); // Trigger when either startDate or endDate changes

  useEffect(() => {
    fetch_admins_data(
      formatDate(startDate),
      formatDate(endDate),
      currentPage,
      pageLimit
    );
  }, [startDate, endDate, currentPage, pageLimit]); // Trigger when either startDate or endDate changes

  const handleNextPage = () => {
    if (currentPage * pageLimit < totalRecords) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(Math.ceil(totalRecords / pageLimit));
  };

  const handlePageLimitChange = (e) => {
    setPageLimit(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page
  };

  console.log(adminGraph, "adminGraph");
  console.log(adminGraph.adminCount, "seriesGraph.adminCount");

  return (
    <div className="container-fluid tw-mb-16">
      <div className="tw-sticky tw-top-12 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
        <div className="tw-w-full  tw-pb-3">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h4 className="tw-text-gray-600 tw-m-0">Engagement Analytics</h4>
            <div className="tw-w-[300px]">
              <CalendarDatePicker
                startDate={startDate}
                endDate={endDate}
                onDateChange={handleDateChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="reports-programs mb-3">
        <div className="row align-items-center">
          <div className="col-md-auto">
            <img
              src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/programperformance.png"
              alt="Invited Members icon"
              style={{ height: "140px" }}
              className="img-fluid mb-0"
            />
          </div>
          <div className="col-md">
            <p className="mb-3">
              The Engagememt Analytics Dashboard provides key performance
              metrics for Engagement Admins and CareNavigators, including total
              conversations, responses, pending responses, and opt-outs. It
              features visualizations like wait time, average response time,
              opt-out trends, and template usage. A performance table with
              detailed metrics and tooltips helps team leads monitor efficiency
              and member engagement.
            </p>
          </div>
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mt-2">
        {analyticsData.map((item, index) => (
          <div key={index} className="tw-px-1">
            <div className="tw-bg-[#f0f4ff] tw-rounded-[14px] tw-h-full">
              <div className="tw-p-2">
                <div className="tw-flex tw-flex-col">
                  <div>
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-1 tw-text-start tw-font-ibm-plex">
                      {item.value}
                    </h1>
                    <p className="tw-text-base tw-text-gray-500 tw-flex tw-items-center tw-justify-start">
                      {item.title}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{item.tooltip}</Tooltip>}
                      >
                        <i className="bx bx-info-circle tw-ml-1 tw-cursor-pointer"></i>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                CareNavigator Responses
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Total number of CareNavigator responses
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <></>
            ) : seriesGraph.cityMember &&
              seriesGraph.cityMember.dataValue.length > 0 ? (
              <MembesAccrossPathwayMonthsBar
                dataMonth={seriesGraph.cityMember}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by CareNavigator
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average response time by care navigator
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>

            {loading ? (
              <></>
            ) : seriesGraph.cnResponseData &&
              seriesGraph.cnResponseData.dataValue.length > 0 ? (
              <MembesAccrossPathwayMonthsBar
                dataMonth={seriesGraph.cnResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-3 tw-gap-4">
        {/* WhatsApp Coordinator Responses Graph */}
        <div className="tw-col-span-1">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                WhatsApp Engagement Admin Responses (2)
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      WhatsApp Engagement Admin response distribution
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <></>
            ) : adminGraph.adminCount &&
              adminGraph.adminCount.dataValue.length > 0 ? (
              <MembesAccrossPathwayMonthsBar
                dataMonth={adminGraph.adminCount}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>

        {/* Average Response Time Graph */}
        <div className="tw-col-span-1">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Average Response Time by WhatsApp Engagement Admin
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Average response time by Engagement Admin
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <></>
            ) : adminGraph.adminResponseData &&
              adminGraph.adminResponseData.dataValue.length > 0 ? (
              <MembesAccrossPathwayMonthsBar
                dataMonth={adminGraph.adminResponseData}
              />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>

        {/* Template Breakdown Pie Chart */}
        <div className="tw-col-span-1">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Template-wise Breakdown
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Distribution of template usage
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {loading ? (
              <></>
            ) : adminGraph.templateResult &&
              adminGraph.templateResult.length > 0 ? (
              <MemberPathwayStatusPie series={adminGraph.templateResult} />
            ) : (
              <div className="emty-column">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Care Coordinator Performance Table */}
      {/* <div className="tw-bg-white tw-rounded-xl tw-shadow-md tw-p-6 tw-mt-4">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-6">
          <h1 className="tw-text-xl tw-font-medium tw-text-gray-500">
            Engagement Admin Performance Summary
          </h1>
        </div>

        <div className="tw-overflow-x-auto tw-rounded-lg tw-shadow">
          <table className="tw-w-full tw-border-collapse">
            <thead>
              <tr className="tw-bg-[#d4e6f0]">
                <th
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort("adminName")}
                >
                  <div className="tw-flex tw-items-center">
                    Engagement Admin
                    {getSortIcon("name")}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Name of the Engagement Admin handling conversations.
                        </Tooltip>
                      }
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
                <th
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort("totalConversationsInitiated")}
                >
                  <div className="tw-flex tw-items-center">
                    Total Conversations
                    {getSortIcon("conversations")}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Total number of conversations initiated with the
                          Engagement Admin.
                        </Tooltip>
                      }
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
                <th
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort("totalRespondedConversations")}
                >
                  <div className="tw-flex tw-items-center">
                    Total Responded
                    {getSortIcon("responded")}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Number of conversations where the Engagement Admin
                          responded at least once.
                        </Tooltip>
                      }
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
                <th
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort("pendingConversations")}
                >
                  <div className="tw-flex tw-items-center">
                    Pending Responses
                    {getSortIcon("pending")}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Number of conversations awaiting a response from the
                          Engagement Admin.
                        </Tooltip>
                      }
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
                <th
                  className="tw-px-4 tw-py-3 tw-text-left tw-font-medium tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                  onClick={() => handleSort("avgReplyTimeSeconds")}
                >
                  <div className="tw-flex tw-items-center">
                    Average Wait Time (mins)
                    {getSortIcon("waitTime")}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Average time spent on each conversation by the
                          Engagement Admin.
                        </Tooltip>
                      }
                    >
                      <i className="bx bx-info-circle tw-ml-1"></i>
                    </OverlayTrigger>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {getSortedData(adminsUsers).map((coordinator, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "" : "tw-bg-gray-100"
                  } tw-text-sm`}
                >
                  <td className="tw-px-4 tw-py-3">{coordinator.adminName}</td>
                  <td className="tw-px-4 tw-py-3">
                    {coordinator.totalConversationsInitiated}
                  </td>
                  <td className="tw-px-4 tw-py-3">
                    {coordinator.totalRespondedConversations}
                  </td>
                  <td className="tw-px-4 tw-py-3">
                    {coordinator.pendingConversations}
                  </td>
                  <td className="tw-px-4 tw-py-3">
                    {coordinator.avgReplyTimeSeconds}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination-container">
          <div className="rows-per-page">
            <label>Rows per page: </label>
            <select value={pageLimit} onChange={handlePageLimitChange}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </div>

          <div className="records-info">{currentPage} of {totalRecords} records</div>

          <div className="pagination-controls">
            <button onClick={handleFirstPage} disabled={currentPage === 1}>
              First
            </button>

            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>

            <span>Page {currentPage}</span>

            <button
              onClick={handleNextPage}
              disabled={currentPage * pageLimit >= totalRecords}
            >
              Next
            </button>

            <button
              onClick={handleLastPage}
              disabled={currentPage * pageLimit >= totalRecords}
            >
              Last
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AnalyticsDashboard;
