import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { fetch_agent_enroll_later_pending_list, fetch_available_slot, update_enroll_later_agent,reject_enroll_later_agent } from '../../../services/met/Service';
import DatePicker from 'react-datepicker'; 
import { formatDate } from '../utils/CommonLibrary';
import { format } from 'date-fns';


function NewRequestRegisterLaterTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('All');
    const [selectedCondition, setSelectedCondition] = useState('All');
    
    const [membersEnrollList, setMembersEnrollList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    
    const [selectedDate, setSelectedDate] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [agentEnrollLaterId, setAgentEnrollLaterId] = useState("");
    const [selectedCareNavigator, setSelectedCareNavigator] = useState("");
    const [modalType, setModalType] = useState('pending');

    useEffect(() => {
        fetchAgentMemberEnrollList();
    }, []);
    
    const fetchAgentMemberEnrollList = async () => {
        setIsLoading(true);
        try {
            let response = await fetch_agent_enroll_later_pending_list();
            if(response.data.code === 200){
                setMembersEnrollList(response.data.data);
            }
           
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const openApproveSlotPopup = (id) =>{
        setAgentEnrollLaterId(id); 
        setModalType("approved");  
    }

  function openRejectSlotPopup(id){
    setAgentEnrollLaterId(id); 
    setModalType('reject');  
 }
 

 const closeModal = () => {
    setAgentEnrollLaterId('');
    setModalType(null);
};

    const columns = [
        {
            name: 'Request Created Date',
            selector: row => row.createdDate,
            format: row => row.createdDate,
            sortable: true,
        },
        {
            name: 'Agent Name',
            selector: row => row.agentName,
            format: row => row.agentName,
            sortable: true,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            format: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership No',
            selector: row => row.mainMembershipNo,
            format: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            format: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Mobile No',
            selector: row => row.memberMobile,
            format: row => row.memberMobile,
            sortable: true,
        },
        
        {
            name: 'Program',
            selector: row => row.programName,
            format: row => row.programName,
            sortable: true,
        },
        {
            name: 'Condition',
            selector: row => row.conditionName,
            format: row => row.conditionName,
            sortable: true,
        },

        {
            name: 'Requested Enrollment Date',
            selector: row => row.requestedEnrollmentDate,
            format: row => row.requestedEnrollmentDate,
            sortable: true,
        },
        
        {
            name: 'Action',
            cell: row => (
                <div>
                    <a href="#" className="btn-approve" data-bs-toggle="modal" data-bs-target="#slotBookModal" onClick={() => openApproveSlotPopup(row.id)}>Approve</a> / <a href="#" className="btn-reject" data-bs-toggle="modal" data-bs-target="#slotDeleteModal"  onClick={() => openRejectSlotPopup(row.id)}>Reject</a>
                </div>
            ),
        },
    ];

    
    const handleSearch = async() => {      
        if (selectedDate) {
            
            setIsLoading(true);
            try {
                let response = await fetch_available_slot({ queryDate: format(selectedDate, 'yyyy-MM-dd HH:mm')});
                if(response.data.code === 200){
                    setTimeSlots(response?.data?.data);
                }
               
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        } else {
            alert('Please select both date and time.');
        }
    };

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('All');
        setSelectedCondition('All');
    };

  

    const newFilteredData = membersEnrollList.filter(user => {
        const matchesSearchQuery =
            user.memberName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.membershipNo.toLowerCase().includes(searchQuery.toLowerCase());

            const matchesCondition = selectedCondition === 'All' || user.conditionName === selectedCondition;
            const matchesProgram = selectedProgram === 'All' || user.programName === selectedProgram;
            return matchesSearchQuery && matchesProgram && matchesCondition;
    });

    const isTimeSlotAvailable = (slot) => {
        console.log("slot",slot);
        
        const [slotHour, slotMinute] = slot.split(' ')[0].split(':').map(Number);
        const isPM = slot.includes('PM');
        const slotHour24 = isPM && slotHour !== 12 ? slotHour + 12 : slotHour;

        const selectedDateTime = new Date(selectedDate);
        //selectedDateTime.setHours(selectedTime.getHours(), selectedTime.getMinutes(), 0, 0);

        const slotDateTime = new Date(selectedDate);
       // slotDateTime.setHours(slotHour24, slotMinute, 0, 0);

        return slotDateTime >= selectedDateTime;
    };


    const bookSlot = async () => {
                setIsLoading(true);
            try {

                const now = new Date();
                if (selectedDate < now) {
                    alert('Please select a future date and time.');
                    return;
                }

                if (!selectedSlot) {
                    alert('Please select a time slot.');
                    return;
                }
                 
                let response = await update_enroll_later_agent(
                    {
                        queryDate:formatDate(selectedDate),
                        time:selectedSlot,
                        careNavigatorId: selectedCareNavigator,
                        agentEnrollLaterId : agentEnrollLaterId,
                      }
                );
                if(response.data.code === 200){
                    window.location.href = "/register-later";
                }else{
                    window.location.href = "/register-later";
                }
                
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }

    }
    const handleRejectRequest = async() =>{
        try {
                setIsLoading(true);
                let response = await reject_enroll_later_agent({ agentEnrollLaterId : agentEnrollLaterId})
                if(response.status.code === 200){
                    window.location.href = "/register-later";
                }else{
                    window.location.href = "/register-later";
                }
                
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }

    }

    const handleExport = () => {

        const modifiedData = newFilteredData.map(item => {
            return {
                'Request Created Date': item.createdDate,
                'Agent Name':item.agentName,
                'Member ID': item.membershipNo,
                'Main Membership No': item.mainMembershipNo,
                'Member Name': item.memberName,
                'Mobile No': item.memberMobile,
                'Program': item.programName,
                'Condition': item.conditionName,
                'Requested Enrollment Date': item.requestedEnrollmentDate,
                
            };
        });

        modifiedData.forEach(function(v){ delete v.approvedBy;delete v.approvedDate;delete v.logId;delete v.mobile; delete v.requestStatus; delete v.userId; });

        exportTableData("request_data",modifiedData);
    };
    return (
        <>
        <LoaderLoader isLoading={isLoading} />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search By Member Name or Member ID"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">All Programs</option>
                            <option value="Diabetes Care Program">Diabetes Care Program</option>
                            
                        </select>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedCondition}
                            onChange={e => setSelectedCondition(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">All Conditions</option>
                            <option value="Diabetes Mellitus">Diabetes Mellitus</option>
                            
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={newFilteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />

            {/* {modalType === 'approved' && ( */}
            <div class="modal fade" id="slotBookModal" tabindex="-1" aria-labelledby="slotBookModalLabel" data-bs-backdrop="static" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="slotBookModalLabel">Approve Request</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div className='row'>
                    <div className="col mb-2 pe-1">
                        <label class="col-form-label">Date and Time</label>
                                <DatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                    showTimeSelect
                                    timeInputLabel="Time:"
                                    dateFormat="dd/MM/yyyy h:mm aa" 
                                    placeholderText="Select Date and Time"
                                    className='form-control input-field-ui'
                                    minDate={new Date()} // Disable past dates 
                                    minTime={selectedDate > new Date() ? new Date(new Date().setHours(0,0,0,0)) : new Date()}
                                    maxTime={new Date().setHours(23, 59, 59)}
                                />
                        </div>
                        <div className="col-auto mb-2 mt-4 ps-0">
                            <label class="col-form-label">&nbsp;</label>
                            <button id="searchBtn" className="btn btn-primary program-save-btn mt-3" onClick={handleSearch} style={{width:"140px"}}>Search</button>
                        </div>
                        
                        <div className="col-md-12">
                        {timeSlots[0] ? (<label className="col-form-label">Slot Available</label>) : (<label className="col-form-label">No Slot Available</label>)}
                            <div className="d-flex flex-wrap" style={{justifyContent:"space-between !important"}}>
                            {timeSlots &&
                                        timeSlots.map(
                                          ({
                                            carenavigatorId,
                                            slots: agentSlots,
                                          }) => (
                                            <div className="time_slot-d-flex">
                                              {agentSlots && agentSlots.map((data) => (
                                                <>
                                                <div
                                                  className={`col-auto mb-2 time_slot_book  ${
                                                    data === selectedSlot
                                                      ? "active"
                                                      : ""
                                                  }`} style={{justifyContent:"center"}}  onClick={() => {
                                                    setSelectedSlot(data);
                                                    setSelectedCareNavigator(
                                                      carenavigatorId
                                                    );
                                                  }} >
                                                  {data}
                                                  
                                                </div>
                                                
                                                {/* <button className="btn btn-primary program-save-btn me-2" onClick={bookSlot}>Book</button> */}
                                                
                                                </>
                                              ))}
                                            </div>
                                          )
                                        )}
                            </div>
                        </div>
                        <div className="col-md-12 mt-3 text-center">
                        {timeSlots[0] ? (<button className="btn btn-primary program-save-btn mt-2" onClick={bookSlot} style={{width:"140px"}}>Book</button>) : ("")}
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* )} */}
            

            {/* {modalType === 'reject' && ( */}
            <div class="modal fade" id="slotDeleteModal" tabindex="-1" aria-labelledby="slotDeleteModalLabel" data-bs-backdrop="static" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="slotDeleteModalLabel">Are you sure, you want to reject the enrollment request?</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="button"
                                        className="btn btn-primary program-save-btn me-2"
                                        onClick={() => handleRejectRequest()}
                                        >
                                        Reject
                                        </button>
                                       
                                    </div>
                                    
                        </div>
                
            
            </div>
            </div>
            {/* )} */}
        </>
    );
}

export default NewRequestRegisterLaterTable;
