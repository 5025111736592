import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './WhatsAppTemplates.css';
import WATemplateLibrary from './component/WATemplateLibrary';
import WAActive from './component/WAActive';
import WADeleted from './component/WADeleted';
import { fetchTemplates } from '../../services/mtp/Service.js';
import { useNavigate, useLocation,useSearchParams,Link } from 'react-router-dom';
import WAPendingRequest from './component/WAPendingRequest.jsx';
import { CustomLoader } from '../utils/CommonLibrary.js';


function WhatsAppTemplates() {
  
  // Define state for selectedIndex
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastSync, setLastSync] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(8);
  const [searchParams] = useSearchParams();

  const [perPage, setPerPage] = useState(10); // Rows per page
  const [page, setPage] = useState(1); // Current page
  const [currentRecordsRange, setCurrentRecordsRange] = useState("");
  const [accessRole, setAccessRole] = useState(false);





  const handleTabSelect = (index) => {
    setSelectedIndex(index);
    setCurrentPage(1);
    navigate(`${location.pathname}?tab=${index}`, { replace: true });
    // setTemplates('');
};

  const getTemplates = async (templateStatus, page, limit = recordsPerPage) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchTemplates(templateStatus, page, limit);
      setTemplates(response.data.data);
      //setAccessRole(response.data.accessTemp);
      setLastSync('');
    } catch (err) {
      setError('Error fetching templates. Please try again.');
    } finally {
      setLoading(false);
    }
  };

 
  const handlePageChange = (page) => {
    setCurrentPage(page);
    if (selectedIndex === 0) {
      getTemplates('active', page, recordsPerPage);
    }else if (selectedIndex === 1) {
      getTemplates('active2', page, recordsPerPage);
    } else {
      getTemplates('inactive', page, recordsPerPage);
    }
  };

  
  const handleLimitChange = (newLimit) => {
    setRecordsPerPage(newLimit);
    setCurrentPage(1);
    if (selectedIndex === 0) {
      getTemplates('active', 1, newLimit);
    } else if (selectedIndex === 1)  {
      getTemplates('active2', 1, newLimit);
    }else{
      getTemplates('inactive', 1, newLimit);
    }
  };

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const tab = params.get('tab');
  //   setSelectedIndex(tab ? parseInt(tab, 10) : 0);
  // console.log("selctedIndex",selectedIndex);
  //   if (selectedIndex === 0) {
  //     getTemplates('active', currentPage,);
  //   }else if(selectedIndex === 1) {
  //     getTemplates('active2', currentPage);
  //   }else{
  //     getTemplates('inactive', currentPage,);
  //   }
  // }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    const newIndex = tab ? parseInt(tab, 10) : 0;
    setSelectedIndex(newIndex);
    console.log("selectedIndex", newIndex); // Log the new index here, if needed.
  }, [location.search]);
  
  useEffect(() => {
    if (selectedIndex === 0) {
      const start = (page - 1) * perPage + 1;
      const end = Math.min(page * perPage, templates.totalRecords);
      if (start > 0 && end > 0) {
          setCurrentRecordsRange(`${start}-${end}`);
      }
      getTemplates('active', page, perPage);

    } else if (selectedIndex === 1) {
      getTemplates('active2', currentPage);
    } else {
      getTemplates('inactive', currentPage);
    }
  }, [selectedIndex, currentPage, page, perPage, templates.totalRecords]);

    // Calculate the current records range (e.g., "1-10 of 100")
    const totalPages = Math.ceil(templates.totalRecords / perPage); // Total number of pages

    useEffect(() => {
      const start = (page - 1) * perPage + 1;
      const end = Math.min(start + perPage - 1, templates.totalRecords);
      setCurrentRecordsRange(`${start}-${end}`);
    }, [page, perPage, templates.totalRecords]);

 

  return (
    <>  
      <section className='WhatsAppTemplates'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">WhatsApp Templates</h1>
            </div>
            <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">+ Create New Template</Link></div>
          </div>

          <div className='reports-programs mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8lv9k/3jq47sc8s4.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-3">WhatsApp Templates are pre-approved by Facebook/Meta for compliant communication. The Template Library offers customizable templates for submission. The 'Active' tab shows submitted templates with their status, while the 'Deleted' tab lists removed templates.</p>
              </div>
            </div>
          </div>

          <div className='whatsapp-templates'>
            <div className="row">
              <div className="col-md-12">
                <Tabs selectedIndex={selectedIndex} onSelect={index => handleTabSelect(index)}>
                  <TabList>
                    <Tab>Template Library</Tab>
                    {/* <Tab>Pending Review</Tab> */}
                    <Tab>Active</Tab>
                    <Tab>Deleted</Tab>
                  </TabList>
                  
                  <TabPanel>
                  {templates.templateData && (
                    <WATemplateLibrary 
                      templates={templates}
                      currentPage={currentPage}
                      recordsPerPage={recordsPerPage}
                      onPageChange={handlePageChange}
                      onLimitChange={handleLimitChange}
                      loading={loading}
                      setPerPage={setPerPage}
                      perPage={perPage}
                      setPage={setPage}
                      page={page}
                      totalPages={totalPages}
                    />
                  )}
                  </TabPanel>
                  {/* {accessRole ? (
                  <TabPanel>
                    <div className="table-responsive">
                    {templates.templateData && (
                      <WAPendingRequest 
                        templates={templates} 
                        getTemplates={getTemplates} 
                        lastSync={lastSync}
                      />
                    )}
                    </div>
                  </TabPanel>
                  ) : null } */}
                  
                  <TabPanel>
                    <div className="table-responsive">
                    {templates.templateData && (
                      <WAActive 
                        templates={templates} 
                        getTemplates={getTemplates} 
                        lastSync={lastSync}
                      />
                    )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="table-responsive">
                      {templates.templateData  && <WADeleted templates={templates} />}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhatsAppTemplates;