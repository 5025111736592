import React, { useState, useEffect, useRef, useCallback } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./CreateNewTemplate.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import quillEmoji from "react-quill-emoji";
import "react-quill-emoji/dist/quill-emoji.css";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import DOMPurify from 'dompurify';
import { whatsappTemplateCreation,getPresignedUrl,generateviewurl, fetch_tag_list, fetch_whatsapp_template_data, fetch_template_variable_list } from "../../services/mtp/Service";
import { validateFormWhatTemplateAdd } from "../utils/validationUtils";
import { LoaderLoader } from "../utils/CommonLibrary";
import RanderSection from "./component/RanderSection";
import HeaderOptions from "./component/HeaderOptions";
import WhatsappFlow from "./component/WhatsappFlow";
import VideoPlayer from "./component/VideoPlayer";
import SubmitModal from "./component/SubmitModal";
import InfoModal from "./component/InfoModal";
import axios from "axios";
import VariableMappingRow from "../componenents/VariableMappingRow";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from 'react-router-dom';

const MAX_CHARS = 1000;

const customStyles = `
  .custom-tooltip-wide {
    max-width: 500px !important;
  }
  .custom-tooltip-wide .tooltip-inner {
    max-width: 600px !important;
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #4e4e4e !important;
  }
`;

const previewStyles = `
  .preview-container {
    position: fixed;
    right: 0;
    top: 55%;
    transform: translateY(-50%);
    width: 33.33%; // Equivalent to col-md-4
    // max-height: 35vh;
    overflow-y: auto;
    background-color: transparent;
    // box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  @media (max-width: 768px) {
    .preview-container {
      position: static;
      width: 100%;
      transform: none;
      max-height: none;
      margin-top: 20px;
    }
  }
`;

const TooltipContent = () => (
  <div className="custom-tooltip p-1" style={{ maxWidth: "600px !important" }}>
    <InfoItem
      textColor="text-info"
      iconClass="bi-chat-left-dots"
      bgColor="bg-info"
      text="A template can have a max of 10 Quick Reply buttons."
    />
    <InfoItem
      textColor="text-danger"
      iconClass="bi-telephone"
      bgColor="bg-danger"
      text="A template can have a max of 1 number per template."
    />
    <InfoItem
      textColor="text-warning"
      iconClass="bi-globe2"
      bgColor="bg-warning"
      text="A template can have a max of 2 URL buttons per template."
    />
  </div>
);

const InfoItem = ({ iconClass, bgColor, text, textColor }) => (
  <div
    className={`d-flex align-items-center p-2 ${bgColor} bg-opacity-10 rounded mb-2`}
  >
    <div className={`bg-white p-3 rounded-circle me-3 ${textColor}`}>
      <i className={`bi ${iconClass}`}></i>
    </div>
    <p className="m-0 flex-grow">{text}</p>
  </div>
);

const modules = {
  toolbar: {
    container: [["bold", "italic"], ["emoji"]],
  },
  "emoji-toolbar": true,
  // "emoji-textarea": true,
  "emoji-shortname": true,
};

function CreateNewTemplate() {
  const navigate = useNavigate();
  const [html, setHtml] = useState("");
  const [plainText, setPlainText] = useState('');
  const [variables, setVariables] = useState(0);
  const [variableValues, setVariableValues] = useState({});
  const [headerHasVariable, setHeaderHasVariable] = useState(false);
  const [headerVariableValue, setHeaderVariableValue] = useState('')
  const quillRef = useRef(null);
  const [addSample, setAddSample] = useState(false);
  const [buttonType, setButtonType] = useState("url");
  const [categoryType, setCategoryType] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState("english");
  const [activeTab, setActiveTab] = useState("english");
  const [showModal, setShowModal] = useState(false);
  // Header Image Upload
  const [fileName, setFileName] = useState("Choose JPG or PNG file");
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const documentInputRef = useRef(null);
  // Header Video Upload
  const [videoName, setVideoName] = useState("Choose MP4 file");
  const [videoPreview, setVideoPreview] = useState(null);
  // Header Document Upload
  const [documentName, setDocumentName] = useState("Choose PDF file");
  const [documentPreview, setDocumentPreview] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("None");
  const [sections, setSections] = useState([
    { id: "website", title: "Add Website URL",  type: "URL" , tooltip: "Add a button that leads the user to a webpage  when clicked." },
    { id: "phone", title: "Add Phone Number", type: "PHONE_NUMBER",  tooltip: "Add a button that leads the user to a phone number when clicked." },
    { id: "quickReplies", title: "Add Quick Replies", type: "text", tooltip: "Add a button that allows users to send a predefined response with one click."},
  ]);

  //tooltip label
  const [tips, setTips] = useState(false);
  const [dashboard, setDashboard] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  
  const [variableMappings, setVariableMappings] = useState([
    { variable: "", value: "", fallback: "" },
  ]);

  const handleValueChange = (index, newValue) => {
    const updatedMappings = [...variableMappings];
    updatedMappings[index] = { ...updatedMappings[index], value: newValue, variable: index + 1 };
    setVariableMappings(updatedMappings);
    setVariableValues({
      ...variableValues,
      [index]:newValue
    });
  };

  const handleFallbackChange = (index, newFallback) => {
    const updatedMappings = [...variableMappings];
    updatedMappings[index] = { ...updatedMappings[index], fallback: newFallback , variable: index + 1};
    setVariableMappings(updatedMappings);
   
   
  };
  

  //success and error message popup
  const handleModalClose = () =>{
    if(showMessage){
      navigate("/whatsApp-templates"+`?tab=1`, { replace: true });
      handleClear();
    }
    setSubmitModal(false);
  } 
const [submitModal, setSubmitModal] = useState(true);


  const [websiteUrls, setWebsiteUrls] = useState([{ url: "", title: "", type: "URL" }]);
  const [contactNumber, setContactNumber] = useState([{ phone: "", title: "", type: "PHONE_NUMBER" }]);
  const [phoneNo, setPhoneNo] = useState({ phone: "", title: "", type: "PHONE_NUMBER" });
  const [quickReplies, setQuickReplies] = useState([""]);

  const [collapsedSections, setCollapsedSections] = useState({});

  const [headerText, setHeaderText] = useState("");
  const [footerText, setFooterText] = useState("To stop receiving these messages, reply STOP");
  const [previewData, setPreviewData] = useState({
    header: null,
    body: "",
    footer: "",
    buttons: [],
    quickReplies: [],
  });

  //flow
  const [buttonText, setButtonText] = useState('');
  const [flowId, setFlowId] = useState('');
  const [flowAction, setFlowAction] = useState('Navigate');
  const [screenId, setScreenId] = useState('');

  const [postVideoName, setPostVideoName] = useState("");
  const [postFileName, setPostFileName] = useState('');
  const [templateName, setTemplateName] = useState("");
  const [category, setCategory] = useState("");
  const [language, setLanguage] = useState("english");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneTextName, setPhoneTextName] = useState('');
  //this for text header value

  const [showErrorMessage, setShowErrorMessage] = useState('');
  

  const [postDocumentName, setPostDocumentName] = useState("");

  const [selectnone, setSelectnone] = useState("None");

  //loader
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    messageBody: '',
    file: '',
    category: '',
    phoneNumber: '',
    phoneTextName: '',
    footerText: '',
    templateName: '',
    dashboard: '',
    selectedTags:'',
  })

  const [errors, setErrors] = useState({
    messageBody: '',
    file: '',
    category: '',
    phoneNumber: '',
    phoneTextName: '',
    footerText: '',
    templateName: '',
    dashboard: '',
  });

  const [showMessage, setShowMessage] = useState("");
  const [urlType, setUrlType] = useState("static");
  const [countryCode, setCountryCode] = useState("966");

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  

//video
const [uploadUrl, setUploadUrl] = useState("");
const [uploading, setUploading] = useState("");
const [uploadProgress, setUploadProgress] = useState("");

const [videoUrl, setVideoUrl] = useState("");
const [video, setVideo] = useState("");


const optionDahsboard = [
  { label: "MediCareManage", value: "2" },
  { label: "MemberConnect", value: "3" },
  // { label: "CareCoordinate", value: "4" },
  { label: "CareNavigate", value: "5" },
  { label: "EngageHub", value: "12" },
];
const [optionTags, setOptionTags] = useState([]);
const [templateVariable, setTemplateVariable] = useState([]);


const templateNameErrorRef = useRef(null);
    const categoryErrorRef = useRef(null);
    const dashboardErrorRef = useRef(null);
    const tagsErrorRef = useRef(null);
    const messageBodyErrorRef = useRef(null);

    const scrollToError = (errorFields) => {
      // Map of field names to their refs
      const errorRefs = {
          templateName: templateNameErrorRef,
          category: categoryErrorRef,
          dashboard: dashboardErrorRef,
          selectedTags: tagsErrorRef,
          messageBody: messageBodyErrorRef
      };

      // Find the first error field that has a ref
      for (const field of Object.keys(errorFields)) {
          if (errorFields[field] && errorRefs[field]?.current) {
              errorRefs[field].current.scrollIntoView({ 
                  behavior: 'smooth', 
                  block: 'center' 
              });
              break;
          }
      }
  };
    
  // useEffect(() => {
  //   setShowModal(true);
  // }, []);

  Quill.register(
    {
      "formats/emoji": quillEmoji.EmojiBlot,
      "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
      "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
      "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
    },
    true
  );

  const handleSkip = () => {
    setShowModal(false);
  };

  const handleWatch = () => {
    // Implement the watch functionality here
    console.log("Watch button clicked");
    // For now, we'll just close the modal
    setShowModal(false);
  };

  const getPreviewBody = () => {
    return DOMPurify.sanitize(html)
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "<br>")
      .replace(/\{\{(\d+)\}\}/g, (match, varNumber) =>
        variableValues[varNumber] || match
      );
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = Number(e.dataTransfer.getData("text/plain"));
    const newSections = [...sections];
    const [reorderedItem] = newSections.splice(dragIndex, 1);
    newSections.splice(dropIndex, 0, reorderedItem);
    setSections(newSections);
  };





  useEffect(() => {
    setPreviewData((prevData) => ({
      ...prevData,
      header: getHeaderPreview(),
      body: getPreviewBody(),
      footer: footerText,
      buttons: websiteUrls.map((url) => ({
        type: "url",
        text: url.title,
        url: url.url,
      })),
      quickReplies: quickReplies.filter(reply => reply.trim() !== ''),
      calls: phoneNo.phone ? {
        type: "phone",
        text: phoneNo.title,
        number: phoneNo.phone,
      } : { number: "", text: "" },
    }));
  }, [
    selectedTemplate,
    html,
    footerText,
    websiteUrls,
    phoneNo,
    quickReplies,
    headerText,
    headerVariableValue,
    imagePreview,
    videoPreview,
    documentPreview,
    variableValues,
  ]);

  console.log("previewData", previewData);
  const getHeaderPreview = () => {
    switch (selectedTemplate) {
      case 'Text':
        return headerText.replace(/\{\{1\}\}/g, headerVariableValue || '{{1}}');
      case 'Image':
        return imagePreview;
      case 'Video':
        return videoPreview;
      case 'Document':
        return documentPreview;
      default:
        return null;
    }
  };

  const replaceVariables = (content) => {
    if (!content) return "";

    // URL regex for better link detection
    const urlRegex = /(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/gi;
    
    const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'mil', 'io', 'co', 'ai', 'app', 'dev', 'uk', 'us', 'eu', 'in', 'de', 'fr'];

     
     // First handle line breaks
     content = content.replace(/\\n/g, "<br />");

     // Split content by HTML tags and process each part
     const parts = content.split(/(<[^>]*>)/);
     content = parts.map(part => {
         // If it's an HTML tag, return it unchanged
         if (part.startsWith('<') && part.endsWith('>')) {
             return part;
         }
         // If part contains Arabic/Urdu, wrap it in RTL div
         if (/[\u0600-\u06FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(part)) {
             return `<div class="tw-text-right" dir="rtl" style="text-align: right;">${part}</div>`;
         }
         // If it's non-empty text without Arabic/Urdu, wrap it in regular div
         if (part.trim()) {
             return `<div>${part}</div>`;
         }
         // Return empty parts unchanged
         return part;
     }).join('');

     console.log(content,'content')

    return (
      content
        // Handle line breaks first
        // .replace(/\\n/g, "<br />")

        // Bold: *text* (not preceded or followed by space)
        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

        // Italic: _text_ (not preceded or followed by space)
        .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

        // Strikethrough: ~text~ (not preceded or followed by space)
        .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

        // Monospace: ```text```
        .replace(/```(.*?)```/g, "<code>$1</code>")

        // Single line code: `text`
        .replace(/`(.*?)`/g, "<code>$1</code>")

        // Enhanced URL detection and replacement
        .replace(urlRegex, (url) => {
          // Check if this matches common URL patterns
          const isLikelyURL = commonTLDs.some(tld => 
            url.toLowerCase().includes(`.${tld}`) &&
            url.match(/^[^@\s]+\.[^\s]+$/) // Exclude email addresses
          );

          if (!isLikelyURL) return url;

          // Add protocol if missing
          let fullUrl = url;
          if (!url.match(/^https?:\/\//i)) {
            fullUrl = 'https://' + url;
          }

          // Validate URL
          try {
            new URL(fullUrl);
            return `<a href="${fullUrl}" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
          } catch {
            return url;
          }
        })

        // WhatsApp style quotes: > at start of line
        .replace(
          /(^|\n)&gt; ?([^\n]*)/g,
          '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
        )

        // Lists: - or • at start of line
        .replace(
          /(^|\n)[-•] ?([^\n]*)/g,
          '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>'
        )

        // Handle multiple underscores or asterisks in a single word
        .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
        .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

        // Fix any potentially broken HTML from nested formatting
        .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
    );
  };

  const renderPreview = () => (
    <div className="iphone">
      <div className="border">
        <div className="tw-bg-white tw-w-1/3 tw-mx-auto tw-text-center tw-text-[10px] tw-mb-2 tw-font-semibold tw-rounded-lg">Today</div>
      {(previewData.header || 
  previewData.body || 
  previewData.footer || 
  (previewData.buttons && previewData.buttons.some(button => button.url && button.text)) ||
  (previewData.quickReplies && previewData.quickReplies.some(reply => reply.trim() !== '')) ||
  (previewData.calls?.number && previewData.calls?.text)) && (
        <div className="quate-bg">
          <div className="row">
            <div className="col-12">
              {previewData.header && (
                <div className="preview-header">
                  {selectedTemplate === "Text" && (
                    <h6>{previewData.header}</h6>
                  )}
                  {selectedTemplate === "Image" && (
                    <img
                      src={previewData.header}
                      alt="Header Image"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                  {selectedTemplate === "Video" && (
                    <VideoPlayer videoPreview={previewData.header} height={100} />
                  )}
                  {selectedTemplate === "Document" && (
                    // <a
                    //   href={documentPreview}
                    //   download={documentName}
                    //   className="document-download-link"
                    //   style={{
                    //     display: 'flex',
                    //     alignItems: 'center',
                    //     color: '#0d6efd',
                    //     textDecoration: 'none',
                    //     padding: '10px',
                    //     border: '1px solid #0d6efd',
                    //     borderRadius: '5px',
                    //     marginBottom: '10px'
                    //   }}
                    // >
                    //   <i className="bi bi-file-earmark-pdf" style={{ marginRight: '10px', fontSize: '24px' }}></i>
                    //   <span>{documentName}</span>
                    // </a>
                    <a
                     href={documentPreview}
                     download={documentName}
                     className="tw-flex tw-items-center tw-gap-3 tw-p-2.5 tw-rounded-md hover:tw-bg-blue-50 tw-transition-colors tw-w-full tw-max-w-md"
                     style={{
                       border: '1px solid #0d6efd'
                     }}
                   >
                     <i className="bi bi-file-earmark-pdf" style={{ marginRight: '10px', fontSize: '24px' }}></i>
                     <span className="tw-text-blue-600 tw-text-xs tw-flex-1 tw-min-w-0" style={{ 
  wordBreak: 'break-word', 
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
  hyphens: 'auto'
}}>
  {documentName}
</span>
                   </a>
                  )}
                </div>
              )}
              <h2
                className="quate-title"
                dangerouslySetInnerHTML={{ __html: replaceVariables(previewData.body) }}
              ></h2>
              {previewData.footer && (
                <h3 className="quate-subtitle">{previewData.footer}</h3>
              )}
              {/* <h3 className="quate-time">02:23 Pm</h3> */}
            </div>

            {(previewData.buttons.some(button => button.url && button.text) ||
              previewData.quickReplies.some(reply => reply.trim() !== '') ||
              (previewData.calls?.number && previewData.calls?.text)) && (
                <div className="col-12 text-center px-1">
                  {previewData.buttons.map((button, index) => (
                    button.url && button.text && (
                      <div key={index}>
                        <hr className="my-2" />
                        <a
                          href={button.url}
                          className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                        >
                          <i className="bx bx-link-external"></i> {button.text}
                        </a>
                      </div>
                    )
                  ))}
                  {previewData.calls.number && previewData.calls.text && (
                    <div>
                      <hr className="my-2" />
                      <a
                        href={`tel:${previewData.calls.number}`}
                        className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                      >
                        <i className="bi bi-telephone-fill"></i> {previewData.calls.text}
                      </a>
                    </div>
                  )}
                  {previewData.quickReplies.map((reply, index) => (
                    reply.trim() !== '' && (
                      <div key={index}>
                        <hr className="my-2" />
                        <button className="btn btn-sm btn-outline-primary m-1">
                          {reply}
                        </button>
                      </div>
                    )
                  ))}
                </div>
              )}
          </div>
        </div>
        )}
      </div>
    </div>
  );

  // const toggleSection = (sectionId) => {
  //   setCollapsedSections((prev) => ({
  //     ...prev,
  //     [sectionId]: !prev[sectionId],
  //   }));
  // };

  // const addWebsiteUrl = () => {
  //   if (websiteUrls.length < 2) {
  //     setWebsiteUrls([...websiteUrls, { url: "", title: "", type: "URL" }]);
  //   }
  // };
  // const addContactNumber = () => {
  //   if (contactNumber.length < 1) {
  //     setContactNumber([...contactNumber, { phone: "", title: "", type: "PHONE_NUMBER" }]);
  //   }
  // };

  // const removeWebsiteUrl = (index) => {
  //   const newUrls = websiteUrls.filter((_, i) => i !== index);
  //   setWebsiteUrls(newUrls);
  // };

  // const addQuickReply = () => {
  //   if (quickReplies.length < 10) {
  //     setQuickReplies([...quickReplies, ""]);
  //   }
  // };

  // const removeQuickReply = (index) => {
  //   const newReplies = quickReplies.filter((_, i) => i !== index);
  //   setQuickReplies(newReplies);
  // };

  // const renderSection = (section, index) => {
  //   const isCollapsed = collapsedSections[section.id];

  //   const commonHeader = (
  //     <div
  //       className="mb-2"
  //       onClick={() => toggleSection(section.id)}
  //       style={{ cursor: "pointer" }}
  //     >
  //       <div className="tw-flex tw-flex-row tw-justify-start gap-2 tw-items-center">
  //         <i className="bi bi-grip-vertical tw-cursor-pointer tw-mt-1"></i>
  //         <input
  //           className="form-check-input form-check-input-ui"
  //           type="checkbox"
  //           name={`Add${section.id}`}
  //           id={`flexCheck${section.id}`}
  //           onClick={(e) => e.stopPropagation()}
  //         />
  //         <label
  //           className="form-check-label mt-1"
  //           htmlFor={`flexCheck${section.id}`}
  //         >
  //           {section.title}
  //         </label>
  //         <i
  //           className={`bi bi-chevron-${isCollapsed ? "down" : "up"} ms-auto`}
  //         ></i>
  //       </div>
  //     </div>
  //   );

  //   const contentClass = `row align-items-center  ${isCollapsed ? "d-none" : ""
  //     }`;

  //   switch (section.id) {
  //     case "website":
  //       return (
  //         <div
  //           className="col-12 mb-4"
  //           key={section.id}
  //           draggable
  //           onDragStart={(e) => handleDragStart(e, index)}
  //           onDragOver={handleDragOver}
  //           onDrop={(e) => handleDrop(e, index)}
  //         >
  //           {commonHeader}
  //           <div className={contentClass}>
  //             {websiteUrls.map((url, urlIndex) => (
  //               <React.Fragment key={urlIndex}>
  //                 <div className="col-3">
  //                   <select
  //                     className="form-select input-field-ui"
  //                     id=""
  //                     name=""
  //                     aria-label="Select Month"
  //                   >
  //                     <option value="">Static</option>
  //                     <option>1</option>
  //                     <option>2</option>
  //                   </select>
  //                 </div>
  //                 <div className="col-8 px-0">
  //                   <input
  //                     type="text"
  //                     className="form-control input-field-ui"
  //                     placeholder="Enter URL, (Eg: https://example.com/test)"
  //                     value={url.url}
  //                     onChange={(e) => {
  //                       const newUrls = [...websiteUrls];
  //                       newUrls[urlIndex].url = e.target.value;
  //                       setWebsiteUrls(newUrls);
  //                     }}
  //                   />
  //                 </div>
  //                 <div className="col-1">
  //                   <button
  //                     className="btn tw-text-orange-600"
  //                     onClick={() => removeWebsiteUrl(urlIndex)}
  //                   >
  //                     <i className="bx bxs-x-circle"></i>
  //                   </button>
  //                 </div>
  //                 <div className="col-11 pe-0 mt-2 mb-2">
  //                   <div className="position-relative d-flex align-items-center">
  //                     <input
  //                       type="text"
  //                       className="form-control input-field-ui"
  //                       placeholder="Enter text for the button"
  //                       value={url.title}
  //                       onChange={(e) => {
  //                         const newUrls = [...websiteUrls];
  //                         newUrls[urlIndex].title = e.target.value;
  //                         setWebsiteUrls(newUrls);
  //                       }}
  //                     />
  //                     <div className="limited-inputs">0/25</div>
  //                   </div>
  //                 </div>
  //               </React.Fragment>
  //             ))}
  //             {websiteUrls.length < 2 && (
  //               <div className="col-12 mt-2">
  //                 <a
  //                   type="button"
  //                   className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
  //                   onClick={addWebsiteUrl}
  //                 >
  //                   <i className="bx bxs-plus-circle"></i> Add another website
  //                   URL
  //                 </a>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       );
  //     case "quickReplies":
  //       return (
  //         <div
  //           className="col-12 mb-4"
  //           key={section.id}
  //           draggable
  //           onDragStart={(e) => handleDragStart(e, index)}
  //           onDragOver={handleDragOver}
  //           onDrop={(e) => handleDrop(e, index)}
  //         >
  //           {commonHeader}
  //           <div className={contentClass}>
  //             {quickReplies.map((reply, replyIndex) => (
  //               <React.Fragment key={replyIndex}>
  //                 <div className="col-11 pe-0 mb-2">
  //                   <div className="position-relative d-flex align-items-center">
  //                     <input
  //                       type="text"
  //                       className="form-control input-field-ui"
  //                       placeholder="Enter text for the button"
  //                       value={reply}
  //                       onChange={(e) => {
  //                         const newReplies = [...quickReplies];
  //                         newReplies[replyIndex] = e.target.value;
  //                         setQuickReplies(newReplies);
  //                       }}
  //                     />
  //                     <div className="limited-inputs">0/25</div>
  //                   </div>
  //                 </div>
  //                 <div className="col-1 mb-2">
  //                   <button
  //                     className="btn tw-text-orange-600"
  //                     onClick={() => removeQuickReply(replyIndex)}
  //                   >
  //                     <i className="bx bxs-x-circle"></i>
  //                   </button>
  //                 </div>
  //               </React.Fragment>
  //             ))}
  //             {quickReplies.length < 10 && (
  //               <div className="col-12 mt-2">
  //                 <a
  //                   type="button"
  //                   className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
  //                   onClick={addQuickReply}
  //                 >
  //                   <i className="bx bxs-plus-circle"></i> Add another Quick
  //                   Reply
  //                 </a>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       );
  //     case "phone":
  //       return (
  //         <div
  //           className="col-12 mb-4"
  //           key={section.id}
  //           draggable
  //           onDragStart={(e) => handleDragStart(e, index)}
  //           onDragOver={handleDragOver}
  //           onDrop={(e) => handleDrop(e, index)}
  //         >
  //           {commonHeader}
  //           <div className={contentClass}>
  //           {contactNumber.map((mphone, pIndex) => (
  //             <>
  //             <div className="col-3">
  //               <select
  //                 className="form-select input-field-ui"
  //                 id=""
  //                 name=""
  //                 aria-label="Select Month"
  //               >
  //                 <option value="">Static</option>
  //                 <option>1</option>
  //                 <option>2</option>
  //               </select>
  //             </div>
  //             <div className="col-8 px-0">
  //               <div className="position-relative d-flex align-items-center">
  //                 <input
  //                   type="text"
  //                   className="form-control input-field-ui"
  //                   id="templateName"
  //                   name="templateName"
  //                   placeholder="Enter Phone Number"
  //                   value={mphone.phone}
  //                   onChange={(e) => {
  //                     const newPhone = [...contactNumber];
  //                     newPhone[pIndex].phone = e.target.value;
  //                     setContactNumber(newPhone);
  //                   }}
  //                 />
  //                 <div className="limited-inputs">0/25</div>
  //               </div>
  //             </div>
  //             <div className="col-11 pe-0 mt-2">
  //               <div className="position-relative d-flex align-items-center">
  //                 <input
  //                   type="text"
  //                   className="form-control input-field-ui"
  //                   id="templateName"
  //                   name="templateName"
  //                   placeholder="Enter text for the button"
  //                   value={mphone.title}
  //                       onChange={(e) => {
  //                         const newPhone = [...contactNumber];
  //                     newPhone[pIndex].title = e.target.value;
  //                     setContactNumber(newPhone);
  //                   }}
  //                 />
  //                 <div className="limited-inputs">0/25</div>
  //               </div>
  //             </div>
  //             </>
  //           ))}
  //           </div>
            
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    if (language) {
      setSelectedLanguages(language);
      setActiveTab(language);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleRemoveTab = (langToRemove) => {
    const updatedLanguages = selectedLanguages.filter(
      (lang) => lang !== langToRemove
    );
    setSelectedLanguages(updatedLanguages);
    if (activeTab === langToRemove) {
      setActiveTab(updatedLanguages[0] || "");
    }
  };

  useEffect(() => {
    if (html === "") {
      setVariables(0);
    }
    fetchAllTags();
    fetchTemplateVariableList();
    
  }, [html]);

  const fetchAllTags = async () =>{
    try{
      let row = await fetch_tag_list();    
      console.log("row",row);
      const data = row.data.data.map((t) => ({
        label: t.tagName,
        value: t.tagId
      }));
      setOptionTags(data);    
    }catch(error){
      console.log(error);
      
    }
  }

  const fetchTemplateVariableList = async () =>{
    try{
     
      let resp = await fetch_template_variable_list();
      setTemplateVariable(resp.data.data);
    
    }catch(error){
      console.log(error);
    }
  }
  

  const stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const countAllCharacters = (text) => {
    if (!text) return 0;
    
    // Convert emojis and other surrogate pairs to single characters
    return [...text].length;
  };

  const countEmojisInHtml = (html) => {
    if (!html) return 0;
    const emojiRegex = /<span class="ap ap-[^"]+?">[^<]+?<\/span>/g;
    const matches = html.match(emojiRegex);
    return matches ? matches.length : 0;
  };

  const countPlainTextCharacters = (text) => {
    if (!text) return 0;
    
    // Get the current HTML content
    const currentHtml = quillRef.current?.getEditor().root.innerHTML || '';
    
    // Count emojis in the HTML
    const emojiCount = countEmojisInHtml(currentHtml);
    
    // Remove variable placeholders
    const textWithoutVariables = text.replace(/\{\{\d+\}\}/g, '');
    
    // Remove the emoji placeholder characters from the text
    // ReactQuill adds invisible characters around emojis
    const cleanText = textWithoutVariables.replace(/\ufeff/g, '');
    
    // Return the total count: clean text length + number of emojis
    return [...cleanText].length + emojiCount;
  };

   // Count variables in the content
   const countVariables = (content) => {
    const matches = content.match(/\{\{\d+\}\}/g);
    return matches ? matches.length : 0;
  };


  const handleChange = useCallback((content, delta, source, editor) => {
    const text = editor.getText();
    const plainTextLength = countPlainTextCharacters(text);
  
    console.log('Current length:', plainTextLength); // For debugging
  
    if (plainTextLength <= MAX_CHARS) {
      setHtml(content);
      setPlainText(text);
      
      // Count variables in the new content
      const newVariablesCount = countVariables(content);
      setVariables(newVariablesCount);
  
      // Update variableValues
      setVariableValues(prevValues => {
        const newValues = {};
        for (let i = 1; i <= newVariablesCount; i++) {
          if (content.includes(`{{${i}}}`)) {
            newValues[i] = prevValues[i] || '';
          }
        }
        return newValues;
      });
  
      // Handle language detection for each paragraph
      if (source === 'user') {
        const quill = quillRef.current.getEditor();
        const contents = editor.getContents();
  
        // Function to detect Arabic text (returns true if the text contains more Arabic characters than English)
        const isArabicDominant = (text) => {
          const arabicPattern = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/g;
          const englishPattern = /[a-zA-Z]/g;
          
          const arabicMatches = text.match(arabicPattern) || [];
          const englishMatches = text.match(englishPattern) || [];
          
          return arabicMatches.length > englishMatches.length;
        };
  
        // Process each paragraph after a short delay to ensure DOM is updated
        setTimeout(() => {
          const paragraphs = document.querySelectorAll('.ql-editor p');
          paragraphs.forEach(paragraph => {
            const paragraphText = paragraph.textContent.trim();
            if (paragraphText) {
              // Set language based on content
              if (isArabicDominant(paragraphText)) {
                paragraph.setAttribute('lang', 'ar');
                paragraph.style.direction = 'rtl';
              } else {
                paragraph.setAttribute('lang', 'en');
                paragraph.style.direction = 'ltr';
              }
            }
          });
        }, 0);
      }
    } else {
      // If text exceeds limit, prevent the change
      const quill = quillRef.current.getEditor();
      let currentContent = quill.getContents();
      
      // Gradually remove characters until we're under the limit
      while (countPlainTextCharacters(quill.getText()) > MAX_CHARS) {
        currentContent = quill.getContents(0, currentContent.length() - 1);
        quill.setContents(currentContent);
      }
    }
  
    // Check for empty content
    if (content === "<p><br></p>" || content === "" || !text.trim()) {
      setVariables(0);
      setHtml("");
      setPlainText("");
      setVariableValues({});
    }
  }, []);
  


  useEffect(() => {
  if (quillRef.current) {
    const quill = quillRef.current.getEditor();
    quill.on('text-change', (delta, oldDelta, source) => {
      if (source === 'user') {
        const text = quill.getText();
        const plainTextLength = countPlainTextCharacters(text);
        
        if (plainTextLength > MAX_CHARS) {
          const currentContent = quill.getContents();
          let truncatedContent = currentContent;
          
          // Gradually remove characters until we're under the limit
          while (countPlainTextCharacters(quill.getText()) > MAX_CHARS) {
            truncatedContent = quill.getContents(0, truncatedContent.length() - 1);
            quill.setContents(truncatedContent);
          }
        }
        
        // Handle empty content
        if (!text.trim()) {
          setVariables(0);
          setHtml("");
          setPlainText("");
        }
      }
    });
  }
}, []);


  //this for header text
  const handleAddHeaderVariable = () => {
    if (!headerHasVariable) {
      setHeaderText(prevText => `${prevText}{{1}}`);
      setHeaderHasVariable(true);
      setHeaderVariableValue('');
    }
  };

  //this for body text
  const handleAddVariable = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);
      const position = range ? range.index : quill.getLength();

      const newVariableCount = variables + 1;
      setVariables(newVariableCount);

      quill.insertText(position, `{{${newVariableCount}}}`, "user");
      quill.setSelection(position + `{{${newVariableCount}}}`.length);

      // Initialize the variable value
      setVariableValues(prev => ({ ...prev, [newVariableCount]: '' }));
    }
  };

  const editorStyle = {
    height: "160px",
  };

  const editorWrapperStyle = {
    height: "160px",
    marginBottom: "0px",
  };

  const handleBackClick = () => {
    // window.history.back();
    navigate("/whatsApp-templates"+`?tab=1`, { replace: true });
  };

  const handleRadioChange = (event) => {
    setSelectedTemplate(event.target.value);
    setSelectnone(event.target.value);
  };

  // const handleFileChange = (event) => {
  //   const { files } = event.target;
  //   if (files.length > 0) {
  //     const file = files[0];
  //     setFileName(file.name);
  //     setPostFileName(file);
  //     setPostDocumentName('');
  //     setPostVideoName('');
  //     //setFormData({...formData, ['imgUrl']: files});
  //     // Create a FileReader to read the file
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setFileName("Choose JPG or PNG file");
  //     setImagePreview(null);
  //   }
  // };

  // Allowed image types
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
  // Max file size (5MB)
  const maxSize = 8 * 1024 * 1024;

  // const validateFile = (file) => {
  //   // Reset messages
  //   setError('');
  //   setSuccess('');

  //   // Check if file exists
  //   if (!file) {
  //     setError('Please select a file');
  //     return false;
  //   }

  //   // Check file type
  //   if (!allowedTypes.includes(file.type)) {
  //     setError('Only JPEG, PNG, GIF, and WebP images are allowed');
  //     return false;
  //   }

  //   // Check file size
  //   if (file.size > maxSize) {
  //     setError('File size must be less than 5MB');
  //     return false;
  //   }

  //   return true;
  // };

  const handleFileChange = (event) => {
    const { files } = event.target;
    // console.log(files);
    
    if (files.length > 0) {
        const file = files[0];
        // console.log("Type Of Image",files[0].type)
        if (allowedTypes.includes(file.type) && file.size < maxSize ) {
        setFileName(file.name);
        console.log('file upload',event)
        setPostFileName(file);
        setPostDocumentName('');
        setPostVideoName('');
        // Create a FileReader to read the file
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        handleImageDelete();
      }
    } else {
        handleImageDelete();
    }
  };

  const handleImageDelete = () => {
    setFileName("Choose JPG or PNG file");
    setImagePreview(null);
    setPostFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
  }
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const fileType = ['video/mp4', 'video/mov', 'video/3gp', 'video/avi']
    // console.log("TYPE of Video", file.type)
    if (file && fileType.includes(file.type)) {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
      const maxSize = 8; // Maximum file size in MB (10 MB)
      if (fileSizeInMB > maxSize) {
          setShowErrorMessage(`File size exceeds ${maxSize} MB. Please select a smaller file.`)
      }else{ 
          handleUpload(event);
          try {
            console.log('Video file selected:', file.name, 'Size:', file.size, 'Type:', file.type);
            
            if (!file.type.startsWith('video/')) {
              alert('Please select a valid video file.');
              return;
            }
      
            setVideoName(file.name);
            setPostVideoName(file);
            setPostDocumentName('');
            setPostFileName('');
      
            // Create a local URL for the file
            const fileURL = (window.URL ? URL : window.webkitURL).createObjectURL(file);
            console.log("video url",video.videoUrl)
            
      
            console.log('Video preview URL created:', fileURL);
          } catch (error) {
            console.error('Error handling video file:', error);
            alert('An error occurred while processing the video file. Please try again.');
          }
      }
    } else {
      handleVideoDelete();
    }
  };

  const handleDocumentChange = (event) => {
    const { files } = event.target;
    if (files.length > 0 ) {
      const file = files[0];
      // console.log("TYPE OF FILE",file.type);
      if (file.type === "application/pdf" && file.size < maxSize) {
        setDocumentName(file.name);
        setPostDocumentName(file);
        setPostFileName('');
        setPostVideoName('');
        // Create a VideoReader to read the file
        const reader = new FileReader();
        reader.onloadend = () => {
          setDocumentPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        handleDocumentDelete();
      }
    } else {
        handleDocumentDelete();
    }
  };

  // const handleVideoChange = (event) => {
  //   const { files } = event.target;
  //   if (files.length > 0) {
  //     const file = files[0];
  //     setVideoName(file.name);
  //     setPostVideoName(file);
  //     setPostDocumentName('');
  //     setPostFileName('');
  //     //setFormData({...formData, ['file']: files});

  //     // Create a VideoReader to read the file
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setVideoPreview(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setVideoName("Choose MP4 file");
  //     setVideoPreview(null);
  //   }
  // };

  // const handleDocumentChange = (event) => {
  //   const { files } = event.target;
  //   if (files.length > 0) {
  //     const file = files[0];
  //     setDocumentName(file.name);
  //     setPostDocumentName(file);
  //     setPostVideoName('');
  //     setPostFileName('');
  //     //setFormData({...formData, ['file']: files});
  //     // Create a VideoReader to read the file
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setDocumentPreview(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setDocumentName("Choose PDF file");
  //     setDocumentPreview(null);
  //   }
  // };

  // Handle onChange event to update the state (websiteurl)
  const handleInputChange = (e) => {  
      setTemplateName(e.target.value);
  }
  const handleInputCategory = (e) => {
      setCategoryType(e.target.value); 
  } 

  const handleInputdashboard = (selectedOptions) => {
    setDashboard(selectedOptions);
  };
  const handleInputTags = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };
console.log("dashboard",dashboard);
  
  
  const handleInputLanguage= (e) => {
    const { name, value } = e.target;
    if (name === 'language') {
      setLanguage(e.target.value);
    } 
  } 

  const handleInputPhoneNumber = (e) => {
    const { name, value } = e.target;
  if (name === 'phoneNumber') {
      setPhoneNumber(e.target.value);

    } 
  }
  const handleInputPhoneTextName = (e) => {
    const { name, value } = e.target;
  if (name === 'phoneTextName') {
      setPhoneTextName(e.target.value);

    }
  };

//upload images video, pdf and image
  const handleUpload = async (e) => {
    try {
       // setFile(e.target.files[0]);
        const file = e.target.files[0];
        console.log('file',file)
        const formData = new FormData();
        formData.append('image', file);
        const response = await getPresignedUrl(formData);
       console.log("response",response.data.data);
       
        const url = response.data.data;

        if (url) {
            setUploadUrl(url);
            setUploading(true);
            setVideoPreview(url);
            setVideoUrl(url);
            // if (url) {
            //     const result = await axios.put(url, file, {
            //         headers: {
            //             'Content-Type': file.type || 'application/octet-stream',
            //         },
            //         onUploadProgress: (progressEvent) => {
            //             const percentCompleted = Math.round(
            //                 (progressEvent.loaded * 100) / progressEvent.total
            //             );
            //             setUploadProgress(percentCompleted);
            //         },
            //     });
            //     setUploading(false);
            //     setVideoUrl(result.config.url);
            //     if (result.status === 200) {
            //         const res = await generateviewurl({ presignedUrl: result.config.url });
            //         const videoUrl = res.data.data
            //         setVideo((prev) => ({
            //             ...prev,
            //             videoUrl: videoUrl,
            //             videoType: 'library', // or 'youtube' if it's a YouTube link
            //         }));
            //         //alert('File uploaded successfully');
            //         setVideoPreview(url);
            //     } else {
            //         alert('Failed to upload file');
            //     }
            // }
        } else {
            alert('Failed to get upload URL');
        }
    } catch (error) {
        console.error('Error uploading file:', error);
        alert('An error occurred during file upload');
    }
};

const handleSampleValidation = () =>{
    let isValid = Object.values(variableValues).every(v => v === "");
  if(isValid){
    alert("Fallback/Constant Value can not be empty");
    return;
  }
  setAddSample(false);
}


  const onSubmitTemplate = (e) => {
    e.preventDefault();
    setErrors('')
    console.log("url",videoUrl)
    // handleFileChange();
    // handleVideoChange();
    // handleDocumentChange();
      if(Object.values(variableValues).some(v => v === "")){
        setAddSample(true);
        return;
      }
    if (!addSample) {
      const validationErrors = validateFormWhatTemplateAdd({ templateName, categoryType,dashboard,selectedTags, html });
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        scrollToError(validationErrors);
        return;
      } else {
        setIsLoading(true);
        // console.log("submitted",html);
        // console.log("submitted",templateName);
        // console.log("submitted",activeTab);
        // console.log("submitted",category);
        // console.log("submitted",websiteUrls);
        // console.log("qucik",quickReplies);
        // console.log("phoneNumber",phoneNumber);
        // console.log("phoneNumber Text",phoneTextName);
        
        let file = '';
        let fileInput = document.getElementById('fileInput') || document.getElementById('videoInput') || document.getElementById('documentInput');
        if (fileInput) {
          file = fileInput.files[0];
        }
        const formData = new FormData();
        if (postFileName) {
          console.log("postFileName", postFileName);
          formData.append('file', postFileName);
          
        } else if (postVideoName) {
          console.log("postVideoName", postFileName);
          formData.append('file', postVideoName);
        } else if (postDocumentName) {
          console.log("postDocumentName", postDocumentName);
          formData.append('file', postDocumentName);
        } else {
          formData.append('file', new File([], "empty.jpg"));
        }
        const data = {
          'html': html,
          'templateName': templateName,
          'dashboard': dashboard,
          'language': selectedLanguages,
          'category': categoryType,
          'websiteUrls': websiteUrls,
          'quickReplies': quickReplies,
          'phoneNumber': phoneNumber,
          'phoneTextName': phoneTextName,
          'selectnone': selectnone,
          'headerText': headerText,
          'postFileName': postFileName,
          'postVideoName': postVideoName,
          'postDocumentName': postDocumentName,
          'footerText': footerText,
          'variableValues': variableValues,
          'headerVariableValue': headerVariableValue,
          'phoneNo':phoneNo,
          'urlType':urlType,
          'countryCode':countryCode,
          'fileUrl':videoUrl,
          'contentVariables':variableMappings,
          'dashboard':dashboard,
          'selectedTags':selectedTags

        };
        formData.append('data', JSON.stringify(data));
        console.log("sdsadsadsdsad",JSON.stringify(data));
        whatsappTemplateCreation(formData)
          .then((response) => {
            if (response.data.code === 200) {
              setShowMessage(response.data.data.message);
              setShowErrorMessage('');
              setSubmitModal(true);
              //handleClear();

              // const timer = setTimeout(() => {
              //   //navigate("/whatsApp-templates"+`?tab=1`, { replace: true });
              //   handleClear();
              // }, 1000);          
              
            } else {
              console.log("response", response);
              setShowErrorMessage(response.data.data.message);
              setShowMessage('');
              setSubmitModal(true);
            }
            setIsLoading(false);
          });
      }

    }else{
      setAddSample(true);
    }
  }


  const handleClear = () => {
    // Reset all state variables to their initial values
    setHtml("");
    setPlainText("");
    setVariables(0);
    setVariableValues({});
    setHeaderHasVariable(false);
    setHeaderVariableValue("");
    // setQuillRef(useRef(null));
    setAddSample(false);
    setButtonType("url");
    setCategoryType("none");
    setSelectedLanguages("english");
    setActiveTab("english");
    setShowModal(false);
    setFileName("Choose JPG or PNG file");
    setImagePreview(null);
    setVideoName("Choose MP4 file");
    setVideoPreview(null);
    setDocumentName("Choose PDF file");
    setDocumentPreview(null);
    setSelectedTemplate("None");
    setSections([
      { id: "website", title: "Add Website URL", type: "URL", tooltip: "Add a button that leads the user to a webpage when clicked." },
      { id: "phone", title: "Add Phone Number", type: "PHONE_NUMBER", tooltip: "Add a button that leads the user to a webpage when clicked." },
      { id: "quickReplies", title: "Add Quick Replies", type: "text", tooltip: "Add a button that allows users to send a predefined response with one click."},
    ]);
    setTips(false);
    setWebsiteUrls([{ url: "", title: "", type: "URL" }]);
    setContactNumber([{ phone: "", title: "", type: "PHONE_NUMBER" }]);
    setPhoneNo({ phone: "", title: "", type: "PHONE_NUMBER" });
    setQuickReplies([""]);
    setCollapsedSections({});
    setHeaderText("");
    setFooterText("");
    setPreviewData({
      header: null,
      body: "",
      footer: "",
      buttons: [],
      quickReplies: [],
    });
    setButtonText('');
    setFlowId('');
    setFlowAction('Navigate');
    setScreenId('');
    setPostVideoName("");
    setPostFileName('');
    setTemplateName("");
    setCategory("");
    setLanguage("english");
    setPhoneNumber('');
    setPhoneTextName('');
    setShowErrorMessage('');
    setPostDocumentName("");
    setSelectnone("none");
    setIsLoading(false);
    setFormData({
      messageBody: '',
      file: '',
      category: '',
      phoneNumber: '',
      phoneTextName: '',
      footerText: '',
      templateName: '',
    });
    setErrors({
      messageBody: '',
      file: '',
      category: '',
      phoneNumber: '',
      phoneTextName: '',
      footerText: '',
      templateName: '',
    });
    setShowMessage("");
    setUrlType("");
    setSelectedTags([]);
    setDashboard([]);
  };

  const handleVideoDelete = () => {
    setVideoName("Choose MP4 file");
    setVideoPreview(null);
    if (videoInputRef.current) {
      videoInputRef.current.value = "";
  }
  };

  const handleDocumentDelete = () => {
    setDocumentName("Choose PDF file");
    setDocumentPreview(null);
    if (documentInputRef.current) {
      documentInputRef.current.value = "";
  }
  };

  return (
    <>
      <section className="CreateNewTemplate ">
        <div className="container-fluid px-0 mb-3">
          <div className="d-flex align-items-center jusify-content-between pb-3 sticky-top-bar">
            <div
              className="col-md"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-primary back-btn mb-0 me-3"
                onClick={handleBackClick}
              >
                <i className="bi bi-chevron-left me-1"></i> Back
              </button>
              <h1 className="main-title me-3" style={{ margin: "0" }}>
                Create New Template
              </h1>
            </div>
              {!showModal && (
                <h1 className="tw-bg-sky-200 tw-text-base tw-font-bold tw-border tw-border-sky-700 tw-rounded-xl tw-px-2 tw-py-1 tw-text-[#03355b]">
                  Need help getting started?{" "}
                  <a
                    href="#"
                    onClick={() => setShowModal(true)}
                    className="text-primary tw-font-normal hover:tw-underline tw-cursor-pointer"
                  >
                    Click here for WhatsApp template setup tips
                  </a>
                </h1>
              )}
          </div>

          <div className="create-new-template p-3">
          {(showMessage || showErrorMessage) && (
            <SubmitModal
             submitModal={submitModal}
             handleModalClose={handleModalClose}
             successMessage={showMessage}
             errorMessage={showErrorMessage}
             handleClear={handleClear}
            />
          )}
          <form onSubmit={onSubmitTemplate}>
            <LoaderLoader isLoading={isLoading} />
            <div className="tw-flex tw-items-center tw-gap-3 tw-mb-3 tw-shadow-lg tw-rounded-xl p-2 tw-w-[66%]">
              <div className="tw-pt-4 tw-pb-9 tw-w-1/2 tw-relative">
                <label htmlFor="templateName" className="form-label">
                  Add Template Name <span style={{ color: 'red' }}>*</span>
              <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top" placement="top">
                      Enter a unique name for your template. This will help you
                      identify it easily later.
                    </Tooltip>
                  }
                >
                  <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                </OverlayTrigger>
                </label>
                <input
                  type="text"
                  className="form-control tw-pr-4 tw-py-2 tw-rounded-xl tw-w-full tw-border tw-m-1"
                  id="templateName"
                  name="templateName"
                  placeholder="Enter Template Name..."
                  onChange={(e)=>setTemplateName(e.target.value)}
                  value={templateName}
                />
                {errors['templateName'] && (
                <div 
                    ref={templateNameErrorRef} 
                    className="text-danger tw-absolute tw-bottom-0 tw-text-sm"
                >
                    {errors['templateName']}
                </div>
            )}
              </div>

              <div className="tw-pb-4 tw-w-1/2 tw-relative">
              <label
                  htmlFor="templateCategory"
                  className="form-label tw-flex tw-items-center tw-gap-2 tw-relative"
                >
                  Select Category <span style={{ color: 'red' }}>*</span>
                  <i
                    className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2"
                    onMouseEnter={() => setTips(true)}
                    onMouseLeave={() => setTips(false)}
                  ></i>
                  {tips && (
                    <div
                      onMouseEnter={() => setTips(true)}
                      onMouseLeave={() => setTips(false)}
                      className="tw-absolute tw-z-50 -tw-top-5 tw-left-[150px] tw-w-60 tw-bg-white tw-rounded-md tw-border tw-shadow-md"
                    >
                      <div className="tw-m-4">
                        <p className="tw-text-sm">
                          Choose the appropriate category for this template.
                          Categories help to organize and approve your
                          templates.
                        </p>
                        <a
                          href="https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines/"
                          className="tw-text-blue-500 hover:tw-underline tw-text-sm"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          (Click here to check Guidelines)
                        </a>
                      </div>
                    </div>
                  )}
                </label>
                <div className="tw-relative">
            <select
                className="form-select tw-pr-8 tw-py-2 tw-rounded-xl tw-w-full tw-border tw-m-1"
                id="categoryType"
                name="categoryType"
                aria-label="Choose Category"
                value={categoryType}
                onChange={handleInputCategory}
            >
                  <option value="">Choose Category</option>
                  <option title="For generating awareness, driving sales, and achieving business goals" value="marketing">Marketing</option>
                  <option title="Triggered by user actions or requests" value="utility">Utility</option>
                  <option title="Used for sending one-time passcodes for identity verification" value="authentication">Authentication</option>
                </select>
                {errors['category'] && (
                <div 
                    ref={categoryErrorRef}
                    className="text-danger tw-absolute tw-bottom-0 tw-text-sm"
                >
                    {errors['category']}
                </div>
            )}
            </div>
              </div>
              <div className="tw-pb-5 tw-w-1/2 tw-relative">
              <label
                  htmlFor="templateCategory"
                  className="form-label tw-flex tw-items-center tw-gap-2 tw-relative"
                >
                  Select Tags<span style={{ color: 'red' }}>*</span>
              <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top" placement="top">
                      Select Tags
                    </Tooltip>
                  }
                >
                  <i className="bi bi-info-circle tw-cursor-pointer tw-text-sm tw-mt-2 tw-ml-2"></i>
                </OverlayTrigger>
                </label>
                <MultiSelect
  className="rmsc-2 form-select-2 select-input-ui-2 select-input-ui-select-2"
  options={optionTags}
  value={selectedTags}
  onChange={handleInputTags}
  labelledBy="Select Teams to Utilize Template"
  overrideStrings={{
    selectSomeItems: "Choose Tags",
    search: "Search...",
  }}
/>
{errors['selectedTags'] && (
            <div 
                ref={tagsErrorRef}
                className="text-danger tw-absolute tw-bottom-0 tw-text-sm"
            >
                {errors['selectedTags']}
            </div>
        )}
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-gap-3 tw-mb-3 tw-shadow-lg tw-rounded-xl p-2 tw-w-[66%]">
              <div className="tw-pb-5 tw-w-1/2 tw-relative">
              <label
                  htmlFor="templateCategory"
                  className="form-label tw-flex tw-items-center tw-gap-2 tw-relative"
                >
                  Select Teams to Utilize Template<span style={{ color: 'red' }}>*</span>
              <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top" placement="top">
                       Teams to Utilize Template
                    </Tooltip>
                  }
                >
                  <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                </OverlayTrigger>
                </label>
                <MultiSelect
                className="form-control tw-rounded-xl tw-w-full"
              options={optionDahsboard}
              value={dashboard}
              onChange={handleInputdashboard}
              labelledBy="Select Teams to Utilize Template"
            />
              {errors['dashboard'] && (
            <div 
                ref={dashboardErrorRef}
                className="text-danger tw-absolute tw-bottom-0 tw-text-sm"
            >
                {errors['dashboard']}
            </div>
        )} 
              </div>
              
              
              
              </div>
            <div className="tw-rounded-xl tw-shadow-lg tw-w-[66%] tw-mt-6 tw-p-2">
              <div className="row align-items-center mb-3">
                <div className="col-12">
                  <h4 className="mb-0 add-new-template-title">
                      <span>
                        Select Language
                  <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top" placement="top">
                          Select the language in which this message will be
                          sent. This is required for template approval.
                        </Tooltip>
                      }
                    >
                        <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                    </OverlayTrigger>
                        </span>
                    </h4>
                </div>
              </div>
            

            <div className="row align-items-center mb-3">
            <div className="col-12">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="language"
                      id="englishRadio"
                      value="english"
                      checked={selectedLanguages.includes("english")}
                      onChange={handleLanguageChange}
                    />
                    <label className="form-check-label" htmlFor="englishRadio">
                      English
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="language"
                      id="arabicRadio"
                      value="arabic"
                      checked={selectedLanguages.includes("arabic")}
                      onChange={handleLanguageChange}
                    />
                    <label className="form-check-label" htmlFor="arabicRadio">
                      Arabic
                    </label>
                  </div>
                </div>
            </div>

            {/* {selectedLanguages.length > 0 && (
              <div>
                <ul className="nav nav-tabs">
                  {selectedLanguages.map((lang) => (
                    <li className="nav-item" key={lang}>
                      <button
                        className={`nav-link ${activeTab === lang ? "active" : ""
                          }`}
                        onClick={() => handleTabClick(lang)}
                      >
                        {lang.charAt(0).toUpperCase() + lang.slice(1)}

                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveTab(lang);
                          }}
                          className=" tw-text-orange-600 tw-ml-2 tw-pb-0 tw-cursor-pointer"
                        >
                          <i className="bx bxs-x-circle"></i>
                        </button>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )} */}
            </div>

            <div className="row mt-4 mb-3">
              <div className="col-md-8">
                <div className="col-12 mb-4">
                  {activeTab === "english" && (
                    <>
                      <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
                        {/* <h4 className="mb-0 add-new-template-subtitle">
                          Template for English UK Language
                        </h4> */}
                      </div>
                      <div className="tw-w-full tw-p-3 tw-rounded-xl tw-shadow-lg">
                        <div className="col-12 mb-2">
                          <h4 className="mb-0 add-new-template-subtitle1">
                              <span>
                                Header (Optional)
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Choose whether to add a header. Headers can
                                  include text, images, videos, or documents to
                                  make the message more engaging.{" "}
                                </Tooltip>
                              }
                            >
                                <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                                </span>
                          </h4>
                        </div>
                        <div className="col-12">
                          {" "}
                          <label className="form-check-label pt-1">
                            Add a title or select the media type you want to
                            get approved for this template's header
                          </label>
                        </div>

                        <HeaderOptions
                          selectedTemplate={selectedTemplate}
                          setSelectedTemplate={setSelectedTemplate}
                          setFileName={setFileName}
                          setImagePreview={setImagePreview}
                          setVideoName={setVideoName}
                          setVideoPreview={setVideoPreview}
                          setDocumentName={setDocumentName}
                          setDocumentPreview={setDocumentPreview}
                          headerText={headerText}
                          setHeaderText={setHeaderText}
                          fileName={fileName}
                          imagePreview={imagePreview}
                          videoName={videoName}
                          videoPreview={videoPreview}
                          documentName={documentName}
                          documentPreview={documentPreview}
                          headerHasVariable={headerHasVariable}
                          setHeaderHasVariable={setHeaderHasVariable}
                          headerVariableValue={headerVariableValue}
                          setHeaderVariableValue={setHeaderVariableValue}
                          handleAddHeaderVariable={handleAddHeaderVariable}
                          setPostFileName={setPostFileName}
                          postFileName={postFileName}
                          setPostVideoName={setPostVideoName}
                          postVideoName={postVideoName}
                          setPostDocumentName={setPostDocumentName}
                          postDocumentName={postDocumentName}
                          handleVideoChange={handleVideoChange}
                          handleVideoDelete={handleVideoDelete}
                          handleDocumentChange={handleDocumentChange}
                          handleDocumentDelete={handleDocumentDelete}
                          handleFileChange={handleFileChange}
                          handleImageDelete={handleImageDelete}
                          handleRadioChange={handleRadioChange}
                          setSelectnone={setSelectnone}
                          selectnone ={selectnone}
                          error={error}
                          success={success}
                          fileInputRef={fileInputRef}
                          videoInputRef={videoInputRef}
                          documentInputRef={documentInputRef}
                          view={false}
                        />
                      </div>

                      <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-mt-4 tw-shadow-lg tw-rounded-xl tw-p-3">
                        <div className="tw-w-full tw-flex tw-items-center tw-justify-between tw-mb-2">
                          <h4 className="mb-0 add-new-template-subtitle1">
                              <span>
                                Message Body
                          <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Enter the main content of your message here.
                                  Make sure it's clear and concise. You can add
                                  variables for personalization.
                                </Tooltip>
                              }
                            >
                                <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                                </span>
                          </h4>
                          <button
                            onClick={() => setAddSample(true)}
                            className="btn btn-primary program-submit-btn me-2"
                            disabled={Object.entries(variableValues).length === 0}
                          >
                            Add Sample
                          </button>
                        </div>
                        <div className="col-12 mb-3">
                          <label className="form-check-label pt-1">
                          Compose Whatsapp message in English
                          </label>
                        </div>
                        <div className="col-12 tw-mb-5">
                          <div
                            className="tw-relative"
                            style={{ minHeight: "200px" }}
                          >
                            <ReactQuill
                              ref={quillRef}
                              value={
                                // activeTab === "english" && 
                                html
                                // .replace(/<p class="ql-align-right">/g, '<p class="ql-align-left">')
                                // .replace(/<h1 class="ql-align-right">/g, '<h1 class="ql-align-left">')
                                // .replace(/<h2 class="ql-align-right">/g, '<h2 class="ql-align-left">')
                                // .replace(/<h3 class="ql-align-right">/g, '<h3 class="ql-align-left">')
                                // .replace(/<h4 class="ql-align-right">/g, '<h4 class="ql-align-left">')
                                // .replace(/<h5 class="ql-align-right">/g, '<h5 class="ql-align-left">')
                                // .replace(/<h6 class="ql-align-right">/g, '<h6 class="ql-align-left">')
                              }
                              onChange={handleChange}
                              modules={modules}
                              className="tw-form-control tw-p-0 tw-border-0"
                              placeholder="Enter text here ..."
                              theme="snow"
                              style={{
                                height: "200px",
                                backgroundColor: "#fff",
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                              }}
                            />
                            <div
                              className="tw-absolute tw-top-3 tw-right-2 tw-text-sm tw-text-gray-500"
                              style={{ pointerEvents: "none" }}
                            >
                              {countPlainTextCharacters(plainText)}/{MAX_CHARS}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <a
                            type="button"
                            className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                            onClick={(e) => {
                              e.preventDefault();
                              handleAddVariable();
                            }}
                          >
                            <i className="bx bxs-plus-circle"></i> Add variable
                          </a>
                              <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top" placement="top">
                                        Insert dynamic content like user names or order numbers. The variables will be replaced with actual data when the message is sent.
                                      </Tooltip>
                                    }
                                  >
                          <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                          </OverlayTrigger>
                        </div>
                        {errors['messageBody'] && (
            <div 
                ref={messageBodyErrorRef}
                className="tw-text-left tw-text-sm tw-w-full text-danger"
            >
                {errors['messageBody']}
            </div>
        )}
                      </div>
                      <div className="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-my-3 tw-p-3 tw-shadow-lg tw-rounded-xl">
                        <p className="tw-font-semibold tw-text-base">
                          <span>
                          Footer (Optional)
                        <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Add a short, one-line footer to your message. This could include a disclaimer or additional contact information.
                                </Tooltip>
                              }
                            >
                          <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                          </span>
                          </p>
                        <p>
                          Add a short line of text to the bottom of your message
                          template.
                        </p>
                        <div className="tw-relative">
                          <input
                            type="text"
                            className="form-control input-field-ui"
                            style={{ paddingRight: "70px" }}
                            id="footerInput"
                            value={footerText}
                            onChange={(e) => setFooterText(e.target.value.slice(0, 60))}
                          />
                          <div className="tw-absolute tw-top-3 tw-h-full tw-right-2 tw-text-gray-400">
                            {footerText.length}/60
                          </div>
                        </div>
                      </div>
                       {selectnone === 'Text' &&
                      <div className="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-my-3 tw-p-3 tw-shadow-lg tw-rounded-xl">
                        <div className="col-md-4 mb-3">
                          <label
                            htmlFor="templateCategory"
                            className="form-label">
                           <strong>Button Type <span className="tw-text-red-500">*</span></strong>
                        <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Choose the type of button to add. You can select 'Call to Action' or 'Quick Reply' to make your message interactive.
                                </Tooltip>
                              }
                            >
                           <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                          </label>
                        <select
                          className="form-select input-field-ui"
                          id="conditionId"
                          name="selectMonth"
                          aria-label="Select Month"
                          onChange={(e) => setButtonType(e.target.value)}
                        >
                          {/* <option value="none">None</option> */}
                          <option value="url">URL and Phone Number</option>
                          <option value="qreplies">Quick Replies</option>
                          {/* {categoryType === "marketing" ? (
                            <option value="flow">Whatsapp Flow</option>
                          ) : null} */}
                        </select>
                      </div>

                      {buttonType === "url" && (
                        <>
                          <div className="row align-items-center mt-4">
                            <div className="col-12 mb-2">
                              <h4 className="mb-0 add-new-template-subtitle1">
                                URL and Phone Number
                              </h4>
                            </div>
                            <div className="col-12 mb-3">
                              <label className="form-check-label pt-1">
                                Create Website URLS and Phone Numbers that let members respond to
                                your message or take action.
                              </label>
                            </div>

                            <div className="col-md-12 mb-3">
                              <div className="copy-code-box">
                                <div className="row">
                                  {sections.map((section, index) => (
                                    <RanderSection
                                      section={section}
                                      index={index}
                                      sections={sections}
                                      setSections={setSections}
                                      collapsedSections={collapsedSections}
                                      setCollapsedSections={
                                        setCollapsedSections
                                      }
                                      websiteUrls={websiteUrls}
                                      setWebsiteUrls={setWebsiteUrls}
                                      quickReplies={quickReplies}
                                      setQuickReplies={setQuickReplies}
                                      phoneNo={phoneNo}
                                      setPhoneNo={setPhoneNo}
                                      urlType={urlType}
                                      setUrlType={setUrlType}
                                      setCountryCode={setCountryCode}
                                      countryCode ={countryCode}
                                      buttonType={buttonType}
                                      view={false}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {buttonType === "qreplies" && (
                         <>
                         <div className="row align-items-center mt-4">
                           <div className="col-12 mb-2">
                             <h4 className="mb-0 add-new-template-subtitle1">
                               Quick Replies
                             </h4>
                           </div>
                           <div className="col-12 mb-3">
                             <label className="form-check-label pt-1">
                               Create Quick Replies that let members respond to
                               your message or take action.
                             </label>
                           </div>

                           <div className="col-md-12 mb-3">
                             <div className="copy-code-box">
                               <div className="row">
                                 {sections.map((section, index) => (
                                   <RanderSection
                                     section={section}
                                     index={index}
                                     sections={sections}
                                     setSections={setSections}
                                     collapsedSections={collapsedSections}
                                     setCollapsedSections={
                                       setCollapsedSections
                                     }
                                     websiteUrls={websiteUrls}
                                     setWebsiteUrls={setWebsiteUrls}
                                     quickReplies={quickReplies}
                                     setQuickReplies={setQuickReplies}
                                     phoneNo={phoneNo}
                                     setPhoneNo={setPhoneNo}
                                     urlType={urlType}
                                     setUrlType={setUrlType}
                                     setCountryCode={setCountryCode}
                                     countryCode ={countryCode}
                                     buttonType={buttonType}
                                     view={false}
                                   />
                                 ))}
                               </div>
                             </div>
                           </div>
                         </div>
                       </>
                      )}
                      {buttonType === "flow" && (
                        <>
                          <WhatsappFlow
                            buttonText={buttonText}
                            setButtonText={setButtonText}
                            flowId={flowId}
                            setFlowId={setFlowId}
                            flowAction={flowAction}
                            setFlowAction={setFlowAction}
                            screenId={screenId}
                            setScreenId={setScreenId}
                          />
                        </>
                      )}
                      </div>
                      }
                     
                    </>
                  )}
                  {activeTab === "arabic" && (
                    <>
                      <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
                        {/* <h4 className="mb-0 add-new-template-subtitle">
                          Template for Arabic Language
                        </h4> */}
                      </div>
                      <div className="tw-w-full tw-p-3 tw-rounded-xl tw-shadow-lg">
                        <div className="col-12 mb-2">
                          <h4 className="mb-0 add-new-template-subtitle1">
                              <span>
                                Header (Optional)
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Choose whether to add a header. Headers can
                                  include text, images, videos, or documents to
                                  make the message more engaging.{" "}
                                </Tooltip>
                              }
                            >
                                <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                                </span>
                          </h4>
                        </div>
                        <div className="col-12">
                          {" "}
                          <label className="form-check-label pt-1">
                            Add a title or select the media type you want to
                            get approved for this template's header
                          </label>
                        </div>

                      <HeaderOptions
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        setFileName={setFileName}
                        setImagePreview={setImagePreview}
                        setVideoName={setVideoName}
                        setVideoPreview={setVideoPreview}
                        setDocumentName={setDocumentName}
                        setDocumentPreview={setDocumentPreview}
                        headerText={headerText}
                        setHeaderText={setHeaderText}
                        fileName={fileName}
                        imagePreview={imagePreview}
                        videoName={videoName}
                        videoPreview={videoPreview}
                        documentName={documentName}
                        documentPreview={documentPreview}
                        headerHasVariable={headerHasVariable}
                        setHeaderHasVariable={setHeaderHasVariable}
                        headerVariableValue={headerVariableValue}
                        setHeaderVariableValue={setHeaderVariableValue}
                        handleAddHeaderVariable={handleAddHeaderVariable}
                        activeTab={activeTab}
                        setPostFileName={setPostFileName}
                          postFileName={postFileName}
                          setPostVideoName={setPostVideoName}
                          postVideoName={postVideoName}
                          setPostDocumentName={setPostDocumentName}
                          postDocumentName={postDocumentName}
                          handleVideoChange={handleVideoChange}
                          handleVideoDelete={handleVideoDelete}
                          handleDocumentChange={handleDocumentChange}
                          handleDocumentDelete={handleDocumentDelete}
                          handleFileChange={handleFileChange}
                          handleImageDelete={handleImageDelete}
                          handleRadioChange={handleRadioChange}
                          setSelectnone={setSelectnone}
                          selectnone ={selectnone}
                          error={error}
                          success={success}
                          fileInputRef={fileInputRef}
                          videoInputRef={videoInputRef}
                          documentInputRef={documentInputRef}
                          view={false}
                      />
                      </div>

                      <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-my-4 tw-shadow-lg tw-rounded-xl tw-p-3">
                        <div className="tw-w-full tw-flex tw-items-center tw-justify-between tw-mb-2">
                          <h4 className="mb-0 add-new-template-subtitle1">
                              <span>
                                Message Body
                          <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Enter the main content of your message here.
                                  Make sure it's clear and concise. You can add
                                  variables for personalization.
                                </Tooltip>
                              }
                            >
                                <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                                </span>
                          </h4>
                          <button
                            onClick={() => setAddSample(true)}
                            className="btn btn-primary btn-primary program-submit-btn me-2"
                            disabled={Object.entries(variableValues).length === 0}
                          >
                            Add Sample
                          </button>
                        </div>
                        <div className="col-12 mb-3">
                          <label className="form-check-label pt-1">
                          Compose Whatsapp message in Arabic
                          </label>
                        </div>
                        <div className="col-12 tw-mb-5">
                          <div
                            className="tw-relative"
                            style={{ minHeight: "200px" }}
                          >
                            <ReactQuill
                              ref={quillRef}
                              value={
                                // activeTab === "arabic" && 
                                html
                                // .replace(/<p>/g, '<p class="ql-align-right">')
                                // .replace(/<h1>/g, '<h1 class="ql-align-right">')
                                // .replace(/<h2>/g, '<h2 class="ql-align-right">')
                                // .replace(/<h3>/g, '<h3 class="ql-align-right">')
                                // .replace(/<h4>/g, '<h4 class="ql-align-right">')
                                // .replace(/<h5>/g, '<h5 class="ql-align-right">')
                                // .replace(/<h6>/g, '<h6 class="ql-align-right">')
                              }
                              onChange={handleChange}
                              modules={modules}
                              className="tw-form-control tw-p-0 tw-border-0"
                              placeholder="Text"
                              theme="snow"
                              style={{
                                height: "200px",
                                backgroundColor: "#fff",
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                              }}
                            />
                            <div
                              className="tw-absolute tw-top-3 tw-right-2 tw-text-sm tw-text-gray-500"
                              style={{ pointerEvents: "none" }}
                            >
                              {countPlainTextCharacters(plainText)}/{MAX_CHARS}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <a
                            type="button"
                            className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                            onClick={(e) => {
                              e.preventDefault();
                              handleAddVariable();
                            }}
                          >
                            <i className="bx bxs-plus-circle"></i> Add variable
                          </a>
                              <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top" placement="top">
                                        Insert dynamic content like user names or order numbers. The variables will be replaced with actual data when the message is sent.
                                      </Tooltip>
                                    }
                                  >
                          <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                          </OverlayTrigger>
                        </div>
                      </div>
                      {/* {selectnone === 'Text' && ( 
                        <>          */}
                      <div className=" tw-flex tw-flex-col tw-gap-1 mb-3 ">
                      <p className="tw-font-semibold tw-text-base">
                          <span>
                          Footer (Optional)
                        <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Add a short, one-line footer to your message. This could include a disclaimer or additional contact information.
                                </Tooltip>
                              }
                            >
                          <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                          </span>
                          </p>
                        <p>
                          Add a short line of text to the bottom of your message
                          template.
                        </p>
                        <div className="tw-relative">
                          <input
                            type="text"
                            className="form-control input-field-ui tw-text-end"
                            style={{ paddingRight: "70px" }}
                            id="footerInput"
                            value={footerText}
                            onChange={(e) => setFooterText(e.target.value.slice(0, 60))}
                          />
                          <div className="tw-absolute tw-top-3 tw-h-full tw-right-2 tw-text-gray-400">
                            {footerText.length}/60
                          </div>
                        </div>
                      </div>
                      {selectnone === 'Text' && ( 
                      <div className="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-my-3 tw-p-3 tw-shadow-lg tw-rounded-xl">
                      <div className="col-md-4 mb-3">
                        <label
                          htmlFor="templateCategory"
                          className="form-label"
                        >
                          <strong>Button Type <span className="tw-text-red-500">*</span></strong>
                      <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Choose the type of button to add. You can select 'Call to Action' or 'Quick Reply' to make your message interactive.
                                </Tooltip>
                              }
                            >
                          <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                          </label>
                        <select
                          className="form-select input-field-ui"
                          id="conditionId"
                          name="selectMonth"
                          aria-label="Select Month"
                          onChange={(e) => setButtonType(e.target.value)}
                        >
                          {/* <option value="none">None</option> */}
                          <option value="url">Quick Replies, URL etc.</option>
                        </select>
                      </div>

                      {buttonType === "url" && (
                        <>
                          <div className="row align-items-center mt-4">
                            <div className="col-12 mb-2">
                              <h4 className="mb-0 add-new-template-subtitle1">
                                URL and Phone Number
                              </h4>
                            </div>
                            <div className="col-12 mb-3">
                              <label className="form-check-label pt-1">
                                Create Website URLS and Phone Numbers that let members respond to
                                your message or take action.
                              </label>
                            </div>

                            <div className="col-md-12 mb-3">
                              <div className="copy-code-box">
                                <div className="row">
                                  {sections.map((section, index) => (
                                    <RanderSection
                                      section={section}
                                      index={index}
                                      sections={sections}
                                      setSections={setSections}
                                      collapsedSections={collapsedSections}
                                      setCollapsedSections={
                                        setCollapsedSections
                                      }
                                      websiteUrls={websiteUrls}
                                      setWebsiteUrls={setWebsiteUrls}
                                      quickReplies={quickReplies}
                                      setQuickReplies={setQuickReplies}
                                      phoneNo={phoneNo}
                                      setPhoneNo={setPhoneNo}
                                      urlType={urlType}
                                      setUrlType={setUrlType}
                                      setCountryCode={setCountryCode}
                                      countryCode ={countryCode}
                                      buttonType={buttonType}
                                      view={false}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {buttonType === "qreplies" && (
                         <>
                         <div className="row align-items-center mt-4">
                           <div className="col-12 mb-2">
                             <h4 className="mb-0 add-new-template-subtitle1">
                               Quick Replies
                             </h4>
                           </div>
                           <div className="col-12 mb-3">
                             <label className="form-check-label pt-1">
                               Create Quick Replies that let members respond to
                               your message or take action.
                             </label>
                           </div>

                           <div className="col-md-12 mb-3">
                             <div className="copy-code-box">
                               <div className="row">
                                 {sections.map((section, index) => (
                                   <RanderSection
                                     section={section}
                                     index={index}
                                     sections={sections}
                                     setSections={setSections}
                                     collapsedSections={collapsedSections}
                                     setCollapsedSections={
                                       setCollapsedSections
                                     }
                                     websiteUrls={websiteUrls}
                                     setWebsiteUrls={setWebsiteUrls}
                                     quickReplies={quickReplies}
                                     setQuickReplies={setQuickReplies}
                                     phoneNo={phoneNo}
                                     setPhoneNo={setPhoneNo}
                                     urlType={urlType}
                                     setUrlType={setUrlType}
                                     setCountryCode={setCountryCode}
                                     countryCode ={countryCode}
                                     buttonType={buttonType}
                                     view={false}
                                   />
                                 ))}
                               </div>
                             </div>
                           </div>
                         </div>
                       </>
                      )}
                      </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <style>{previewStyles}</style>
              <div className="preview-container">
                <div className="row align-items-center tw-scale-90">
                  <div className="col-12 mb-2">
                  <h4 className="mb-0 add-new-template-subtitle text-center">
                      <span>
                      Preview
                    <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  This is how your message will appear to users in WhatsApp. Check here to make sure everything looks as expected.
                                </Tooltip>
                              }
                            >
                      <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                      </span>
                    </h4>
                  </div>
                  <div className="col-12">{renderPreview()}</div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-12 text-start">
                  <button
                    type="submit"
                    className="btn btn-primary btn-primary program-save-btn me-2"
                   >
                    Create & Submit
                  </button>
                  <button onClick={handleClear} type="button" className="btn clear-btn">
                    Clear
                  </button>
                </div>
              </div>
            </div>
            </form>
          </div>
        </div>
      </section>

      {/* <Modal show={showModal} onHide={handleSkip} centered>
        <Modal.Header closeButton>
          <Modal.Title>WhatsApp Template Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center ">
            <p className="mb-3">
              Check{" "}
              <span className="">
                Facebook's Best Practices for WhatsApp Template Creation
              </span>
            </p>
            <div className="d-flex justify-content-center gap-3">
              <Button
                style={{
                  backgroundColor: "#03335b",
                  color: "#fff",
                  borderRadius: "40px",
                }}
                onClick={handleWatch}
              >
                <a
                  className="tw-text-white"
                  target="_blank"
                  href="https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines/"
                >
                  View Guidelines
                </a>
              </Button>
              <Button
                style={{
                  backgroundColor: "#fff",
                  color: "#03335b",
                  fontWeight: "700",
                  border: "1px solid #03335b",
                  borderRadius: "40px",
                }}
                onClick={handleSkip}
              >
                Skip
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <InfoModal
      showModal={showModal}
      handleSkip={handleSkip}
      />


      <Modal size="lg" show={addSample} onHide={() => setAddSample(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Sample Content {activeTab === "english" ? "(English)" : "(Arabic)"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <h1 className="mb-3 tw-text-base">
              To help WhatsApp understand what kind of message you want to send,
              you have the option to provide specific examples of the variables
              in your templates by adding samples for them here. In case you
              have a media header in your template, you can also provide a
              sample file for the media header.
            </h1>
            <div className="d-flex flex-column justify-content-start gap-1">
              {previewData.header && (
                <div className="preview-header">
                  {selectedTemplate === "Text" && previewData.header && (
                    <>
                      <p className="tw-font-bold">Header</p>
                      <div
                        dangerouslySetInnerHTML={{ __html: previewData.header }}
                      ></div>
                      {headerHasVariable && (
                        <div className="mt-3">
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control"
                              value={headerVariableValue}
                              onChange={(e) =>
                                setHeaderVariableValue(e.target.value)
                              }
                              placeholder="Enter content for header {{1}}"
                            />
                          </div>
                        
                        </div>
                       
                      )}
                    </>
                  )}
                  {selectedTemplate === "Image" && (
                    <img
                      src={previewData.header}
                      alt="Header Image"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                  {selectedTemplate === "Video" && (
                    <video
                      src={previewData.header}
                      controls
                      style={{ maxWidth: "100%", height: "auto" }}
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}
                  {selectedTemplate === "Document" && (
                    <a
                      href={documentPreview}
                      download={documentName}
                      className="document-download-link"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#0d6efd",
                        textDecoration: "none",
                        padding: "10px",
                        border: "1px solid #0d6efd",
                        borderRadius: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <i
                        className="bi bi-file-earmark-pdf"
                        style={{ marginRight: "10px", fontSize: "24px" }}
                      ></i>
                      <span>Download: {documentName}</span>
                    </a>
                  )}
                </div>
              )}
              {previewData.body && (
  <>
    <p className="tw-font-bold">Body</p>
    <div dangerouslySetInnerHTML={{ __html: previewData.body }}></div>
    <div className="mt-3">
      {Object.entries(variableValues).length > 0 && (
        <div className="variable-table-header d-flex justify-content-between align-items-center mb-2 p-2" style={{ backgroundColor: '#f8f9fa' }}>
          <div className="col-2">
            <strong>Variable</strong>
          </div>
          <div className="col-4">
            <strong>Value</strong>
          </div>
          <div className="col-4">
            <strong>
              Fallback/Constant Value
              <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top" placement="top">
                      <span className="tw-font-bold">Fallback Value:</span> If you’ve linked a changing value (called a variable) to something, but the variable is empty for a member, we use a fallback value instead. We give you a default fallback value, but you can change it if you want.<br/><br/><span className="tw-font-bold">Constant :</span> If you have mapped a constant value to the variable, enter that constant value to be sent here.
                    </Tooltip>
                  }
                >
                  <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                </OverlayTrigger>
              </strong>
          </div>
        </div>
      )}

      {Object.entries(variableValues).map(([varNumber, value]) => (
        <div key={varNumber} className="mb-2">
          <input
            type="hidden"
            className="form-control"
            value={variableMappings[varNumber]?.fallback || ''}
            onChange={(e) =>
              setVariableValues((prev) => ({
                ...prev,
                [varNumber]: e.target.value,
              }))
            }
            placeholder={`Enter content for {{${varNumber}}}`}
          />

          <div className="variable-mapping-row d-flex justify-content-between align-items-center mb-3">
            <div className="col-2">
              <div className="input-group">
                <input 
                  type="text" 
                  className="form-control form-control-sm" 
                  value={`{{${varNumber}}}`} 
                  readOnly 
                />
              </div>
            </div>
            
            <div className="col-4">
              <div className="input-group">
                <select
                  className="form-select"
                  value={variableMappings[varNumber]?.value}
                  onChange={(e) => handleValueChange(varNumber, e.target.value)}
                >
                  <option value="">Choose dynamic/constant value</option>
                  {templateVariable.map((varibale, index)=>(
                    <option key={index} value={varibale.contentName}>{varibale.name}</option>
                  ))}               
                </select>
              </div>
            </div>

            <div className="col-4">
              <div className="input-group">
                <input 
                  type="text" 
                  className="form-control form-control-sm" 
                  value={variableMappings[varNumber]?.fallback || ''}
                  onChange={(e) => handleFallbackChange(varNumber, e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
)}

              {previewData.footer && (
                <>
                  <p className="tw-font-bold">Footer</p>
                  <div>{previewData.footer}</div>
                </>
              )}

              {previewData.buttons &&
                previewData.buttons.some(
                  (button) => button.url && button.text
                ) && (
                  <>
                    <p className="tw-font-bold">URL Buttons</p>
                    {previewData.buttons
                      .filter((button) => button.url && button.text)
                      .map((button, index) => (
                        <div key={index}>
                          <p className="text-primary">
                            <i className="bi bi-dot"></i>
                            {button.text}
                          </p>
                          <p>{button.url}</p>
                        </div>
                      ))}
                  </>
                )}

              {previewData.quickReplies &&
                previewData.quickReplies.some((reply) => reply) && (
                  <>
                    <p className="tw-font-bold">Quick Reply Buttons</p>
                    {previewData.quickReplies
                      .filter((quickReply) => quickReply)
                      .map((quickReply, index) => (
                        <div key={index}>
                          <p className="text-primary">
                            <i className="bi bi-dot"></i>
                            {quickReply}
                          </p>
                        </div>
                      ))}
                  </>
                )}

              {previewData.calls &&
                previewData.calls.text &&
                previewData.calls?.number && (
                  <>
                    <p className="tw-font-bold">Phone Number</p>
                    <div>
                      <p className="text-primary">
                        <i className="bi bi-dot"></i>
                        {previewData.calls?.text}
                      </p>
                      <p>{previewData.calls?.number}</p>
                    </div>
                  </>
                )}
            </div>
            <div className="d-flex justify-content-center gap-3">
              <Button
              // className="tw-bg-[#03335b] hover:tw-bg-[#114876] "
                // style={{
                //   backgroundColor: "#03335b",
                //   color: "#ffffff",
                //   fontWeight: "700",
                //   border: "1px solid #03335b",
                //   borderRadius: "40px",
                // }}
                className="btn btn-primary program-submit-btn"
                onClick = {handleSampleValidation} >
                Done
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateNewTemplate;