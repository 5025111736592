import React,{useEffect, useState} from 'react';
import './LoginPage.css';
import LanguageSetting from './componenents/LanguageSetting';
import axios from "axios";
import { CustomFieldLevelLoader, decruptCipherString } from './utils/CommonLibrary';
import { encryptData } from './utils/Utils';

function LoginPage() {

    const [url,setUrl] = useState('');
    const [loading,showLoading] = useState(true);

    useEffect(() => {

        let isLoggedIn= localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : '';
        if (isLoggedIn) {
            const redirectUrl = decruptCipherString(isLoggedIn);
            window.location.href = redirectUrl;
        }

        const fetchData = async () => {
            axios.post(process.env.REACT_APP_BASE_URL + 'sso/login', {
              })
              .then(function (response) {
                
                if(response.data.code == 200){
                    setUrl(decruptCipherString(response.data.data.link));
                    showLoading(false);
                }else{
                    alert('something went wrong');
                }
               
              })
              .catch(function (error) {
                console.log(error);
              });
        };
        
        fetchData();
    }, []);

    const redirectUrl = () => {
        localStorage.setItem("preLogin", encryptData("Yes"));
        showLoading(true);
        window.location.href = url;
    }

  return (
    <>
        <section className='LoginPage'>
            {/* <div className='language-setting'>
                <LanguageSetting/>
            </div> */}
            <div className="card-login">
                <div className="card-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 ps-md-0 pe-md-5 en-ar-text-align">
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/glwh3/hjthzlv2se.png" className="img-fluid logo-ksa" alt="" />
                                <h1 className="login-title mb-4 mt-5"><span>GOQii</span> HealthEngage</h1>
                                <div className="form-group text-center">
                                    {!loading ? 
                                        <button type="button" className="btn btn-primary btn-login tw-flex tw-mx-auto tw-items-center" onClick={redirectUrl}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/c8g0g/4j3jl2h4f4.png" style={{height:"36px"}} className='me-2' alt="" /> SSO Sign in</button> 
                                        :
                                        <div className='tw-flex tw-justify-center tw-items-center tw-w-full'>
                                        <CustomFieldLevelLoader />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 px-0">
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/88vsv/x1hed4n24u.png" className="img-fluid img-login" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div className='powered-by mt-2'>
             <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/hstog/j1xo7j89r8.png" style={{height:"42px"}} className="" alt="" />
            </div>
        </section>
    </>
  );
}

export default LoginPage;
