import React, { useState, useEffect } from 'react';
import AddUser from '../../../../componenents/UserManagement/AddUser';
import { useNavigate } from 'react-router-dom';
import { hcc_user_creation_data, fetch_ad_users_list, fetch_role_data } from '../../../../../services/hcc/Service';
import { showToast } from '../../../../componenents/ContentCareNavigator/NotificationToast';

const AddMember = () => {
    const navigate = useNavigate();
    
    // Form state
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailId: '',
        roleId: '',
    });

    // Error state
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        emailId: '',
        roleId: '',
    });

    // Loading states
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    // Message states
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Email suggestions state
    const [suggestions, setSuggestions] = useState([]);

    // Role options (fetch from API if needed)
    const [roleOptions, setRoleOptions] = useState([]);

    // Add isDisabled state
    const [isDisabled, setIsDisabled] = useState(true);

    // Add roleData state
    const [roleData, setRoleData] = useState([]);

    // Add useEffect for fetching role data
    useEffect(() => {
        fetchRoleData();
    }, []);

    // Add role data fetching function
    const fetchRoleData = async () => {
        try {
            const response = await fetch_role_data();
            if (response.data.code === 200) {
                setRoleData(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching role data:", error);
        }
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.emailId) errors.emailId = 'Email is required';
        if (!data.firstName) errors.firstName = 'First name is required';
        if (!data.lastName) errors.lastName = 'Last name is required';
        if (!data.roleId) errors.roleId = 'Role is required';
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
        setErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleEmailChange = async (e) => {
        const { name, value } = e.target;
        setFormData({
            firstName: '',
            lastName: '',
            emailId: '',
            roleId: '',
            [name]: value,
        });

        setErrorMessage('');
        
        if (!value) {
            setFormData(prev => ({
                ...prev,
                firstName: '',
                lastName: '',
            }));
            setLoading(false);
            setSuggestions([]);
            return;
        }

        // Fetch suggestions from backend
        if (value) {
            try {
                setLoading(true);
                
                const response = await fetch_ad_users_list(value);
                setSuggestions(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching email suggestions:', error);
                setLoading(false);
            }
        } else {
            setSuggestions([]);
            setLoading(false);
        }

        // if (name === 'emailId' && !validateEmail(value)) {
        //     setErrors(prev => ({
        //         ...prev,
        //         [name]: 'Invalid email format',
        //     }));
        // } else {
        //     setErrors(prev => ({
        //         ...prev,
        //         [name]: '',
        //     }));
        // }
    };

    const handleSuggestionClick = (suggestion) => {
        setFormData(prev => ({
            ...prev,
            emailId: suggestion.mail,
            firstName: suggestion.givenName || '',
            lastName: suggestion.surname || '',
        }));

        setErrors(prev => ({
            ...prev,
            firstName: '',
            lastName: '',
            emailId: '',
        }));

        setSuggestions([]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                setIsLoading(true); //show loader
                const response = await hcc_user_creation_data(formData);
                if(response.data.code == 200){
                    navigate('/hc-member-management');
                    setSuccessMessage(response.data.data.message);
                    // showToast(response.data.data.message, 'success', 'top-right'); 
                    setFormData({ emailId: '', firstName: '', lastName: '', roleId: '' });
                    setErrors({ emailId: '', firstName: '', lastName: '', roleId: '' });
                } else {
                    setErrorMessage(response.data.data.message);
                }
                setIsLoading(false); //hide loader
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        }
    }

    const handleClear = () => {
        setFormData({
            firstName: '',
            lastName: '',
            emailId: '',
            roleId: '',
        });
        setErrors({});
        setSuggestions([]);
        setErrorMessage('');
        setSuccessMessage('');
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className='ContentUserManagement'>
            <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <button 
                        className="btn btn-primary back-btn mb-0 me-3"
                        onClick={handleBack}
                    >
                        <i className="bi bi-chevron-left me-1"></i> Back
                    </button>
                    <div className="col">
                        <h4 className="mb-0 content-main-title">Add New User</h4>
                    </div>
                </div>
            </div>

            <AddUser
                formData={formData}
                handleChange={handleChange}
                handleEmailChange={handleEmailChange}
                handleSubmit={handleSubmit}
                handleClear={handleClear}
                errors={errors}
                loading={loading}
                isLoading={isLoading}
                successMessage={successMessage}
                errorMessage={errorMessage}
                suggestions={suggestions}
                handleSuggestionClick={handleSuggestionClick}
                roleOptions={roleData}
                isDisabled={isDisabled}
            />
        </div>
    );
};

export default AddMember;
