import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {deleteCheckTemplate, deleteTemplate } from '../../../services/mtp/Service.js';
import SubmitModal from './SubmitModal.js';
import { Link } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const StatusBadge = ({ status }) => {
  const badgeStyle = {
    padding: '4px 12px',
    borderRadius: '12px',
    fontSize: '12px',
    fontWeight: '500',
    textTransform: 'capitalize',
  };

  const approvedStyle = {
    ...badgeStyle,
    // backgroundColor: '#E8F5E9',
    color: '#4CAF50',
  };

  const draftStyle = {
    ...badgeStyle,
    // backgroundColor: '#FAA0A0',
    color: '#FF3131',
  };

  const pendingStyle = {
    ...badgeStyle,
    // backgroundColor: '#FFECB3',
    color: '#FFC107',
  }

  return (
    <span style={status.toLowerCase() === 'approved' ? approvedStyle : status.toLowerCase() === 'approval pending' ? pendingStyle : draftStyle}>
      {status}
    </span>
  );
};

function WAActive({templates,getTemplates,lastSync}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [selectedLanguage, setSelectedLanguage] = useState('All');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [submitModal, setSubmitModal] = useState(false);


  const handleShow = async (templateId) => {
    setTemplateToDelete(templateId); // Store the templateId when modal is shown
    let resp = await deleteCheckTemplate(templateId);
    if(resp.data.code === 200){
      setShowErrorModal(true);
      setError(resp.data.data.message);
    }else{
      setShowModal(true);
    }
  };
  const handleClose = () =>{
    setShowModal(false);
    setShowErrorModal(false);
  } 

  const onDeleteTemplate = async (templateId) => {
    try {
      const response = await deleteTemplate(templateId);
      setError("");
      if(response.data.code === 200){
        window.location.reload();
        setShowModal(false);
        
      }else{
        setShowErrorModal(false);
        setError(response.data.message);
        setShowModal(false);
      }
    } catch (err) {
      setError('Error deleting template. Please try again.');
    } finally {
      setLoading(false);
    }
  };

 

  const columns = [
    { name: 'Template Name', selector: (row) => row.templateName, sortable: true },
    { name: 'Status', selector: (row) => row.status, sortable: true, cell: (row) => <StatusBadge status={row.status} /> },
    { 
      name: (
          <div className="tw-flex tw-items-center tw-gap-1">
              Category
              <OverlayTrigger
                  placement="top"
                  overlay={
                      <Tooltip id="category-tooltip" className="tw-max-w-xs">
                          This is the template category defined based on characteristics set during template creation, such as Marketing, Utility, or Authentication.
                      </Tooltip>
                  }
              >
                  <i className="bi bi-info-circle tw-cursor-pointer tw-mt-1 tw-text-xs"></i>
              </OverlayTrigger>
          </div>
      ),
      selector: (row) => row.category,
      sortable: true 
  },
  { 
      name: (
          <div className="tw-flex tw-items-center tw-justify-start tw-gap-2">
              <span>Meta Approved<br/>Category</span>
              <OverlayTrigger
                  placement="top"
                  overlay={
                      <Tooltip id="meta-category-tooltip" className="tw-max-w-xs">
                          This template category is assigned by Meta, based on an assessment of the template's content.
                      </Tooltip>
                  }
              >
                  <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs"></i>
              </OverlayTrigger>
          </div>
      ),
      selector: (row) => row.metaCategory,
      sortable: true 
  },
  
    { name: 'Language', selector: (row) => row.language, sortable: true },
    { name: 'Created By', selector: (row) => row.createdBy, sortable: true },
    {
      name: 'Action',
      cell: (row) => (
        <div className="d-flex align-items-center">
          <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
          <Link to={`/view-template/${row.templateId}`}>
            <span className="tw-px-2">
              <i className="bi bi-eye tw-text-black hover:tw-text-[#215a87]"></i>
            </span>
          </Link>

          </OverlayTrigger>
          <span className="">|</span>
          {/* <div className='vr'></div> */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <span className="tw-px-2" onClick={() => handleShow(row.templateId)}>
              <i className="bi bi-trash tw-text-orange-600 hover:tw-text-[#215a87]"></i>
            </span>
          </OverlayTrigger>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#D4E6EF',
        fontWeight: '500',
        fontSize: '14px',
        whiteSpace: 'normal',
      },
    },
    rows: {
      style: {
        whiteSpace: 'normal',
      },
    },
    cells: {
      style: {
        whiteSpace: 'normal',
      },
    },
  };

  const handleClear = () => {
    setSearchQuery('');
    setSelectedCategory('All');
    setSelectedStatus('All');
    setSelectedLanguage('All');
  };

  const filteredData = templates.templateData?.filter((item) => {
    const matchesSearchQuery = item.templateName.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'All' || item.category.toLowerCase() === selectedCategory.toLowerCase();
    const matchesStatus = selectedStatus === 'All' || item.status.toLowerCase() === selectedStatus.toLowerCase();
    const matchesLanguage = selectedLanguage === 'All' || item.language.toLowerCase() === selectedLanguage.toLowerCase();
    return matchesSearchQuery && matchesCategory && matchesStatus && matchesLanguage;
  });

  return (
    <>
    {submitModal && (
      <SubmitModal
        submitModal={submitModal}
        handleModalClose={() => setSubmitModal(false)}
        successMessage="show"
        errorMessage=""
      />
    )}
      <div className="container-fluid">
        <div className="row mb-3 align-items-center">
        <div className="tw-bg-[#f1f6fc] tw-rounded-md tw-p-3 tw-flex tw-items-center tw-gap-2 tw-mb-2">
      <AiOutlineInfoCircle className="tw-text-gray-600" size={20} />
      <p className="tw-text-sm tw-text-gray-800 tw-mb-0">
        WhatsApp can take up to 24 hours to review (approve / reject) a template.
        <span onClick={() => setSubmitModal(true) } className="tw-ml-1 tw-text-blue-600 tw-font-medium tw-cursor-pointer hover:tw-underline">
          See More
        </span>
      </p>
    </div>
          <div className="col-md d-flex px-1">
            <div className="form-group" style={{ width: '100%' }}>
              <input
                type="text"
                placeholder="Search By Template Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control input-field-ui ps-5"
              />
              <img
                src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
                className="input-icon-seacrh"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-2 px-1">
            <select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              className="form-select input-field-ui"
            >
              <option value="All">--Select Status--</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
              <option value="approval pending">Pending</option>
            </select>
          </div>
          <div className="col-md-2 px-1">
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="form-select input-field-ui"
            >
              <option value="All">--Select Category--</option>
              <option value="marketing">Marketing</option>
              <option value="utility">Utility</option>
              <option value="authentication">Authentication</option>
            </select>
          </div>
          <div className="col-md-2 px-1">
            <select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              className="form-select input-field-ui"
            >
              <option value="All">--Select Language--</option>
              <option value="english">English</option>
              <option value="arabic">Arabic</option>
            </select>
          </div>

          <div className="col-md-auto px-1">
            <button onClick={handleClear} className="btn btn-primary clear-search-btn">
              Clear
            </button>
          </div>
          <div className="col-md-auto pe-1">
            <h6 className="last-sync">Latest sync on</h6>
            <h6 className="last-sync">
              <span>{templates.lastSync || 'Fetching...'}</span>
            </h6>
          </div>
          <div className="col-md-auto px-1">
            <button className="btn btn-outline-dark" onClick={(e) => getTemplates('active2',1)}>
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        paginationPerPage={10}
        striped
        responsive
        noHeader
        customStyles={customStyles}
        
      />

      {showModal && 
         <div className="modal fade show" id="addNewServiceModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div className="modal-body pt-1 mb-2 mx-auto w-80">Are you sure you want to delete it?</div>
                    <div className="mx-auto w-40 mb-4">
                      <button tpe="button" className="btn btn-primary program-save-btn me-2" onClick={()=>onDeleteTemplate(templateToDelete)}>
                        <i className="fas fa-trash-alt"></i>Delete
                      </button>               
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
         </div> 
      }

{showErrorModal && 
         <div className="modal fade show" id="addNewServiceModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div className="modal-body pt-1 mb-2 mx-auto w-80">{error}</div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
         </div> 
      }
    </>
  );
}

export default WAActive;
