import React, {useState} from 'react';
import { Commingsoon, LoaderLoader } from '../../utils/CommonLibrary';
import { Link } from "react-router-dom";
import { validateFormNewConditionAdd, validateFormPathwayAdd } from '../../utils/validationUtils';
import { AddNewPathway, editFetchNewCondition, editNewCondition, removeNewCondition,publishNewCondition,displayMessageCondition } from '../../../services/mtp/Service';
function DropDownAction({conditionId, programId, tab, onCallBackFromChild,ifCheck, isApproved, isPublish}) {

    const [showEditConditionModal, setShowEditConditionModal] = useState(false);
    const [showAddPathwayModal, setShowAddPathwayModal] = useState(false);
    const [showDeleteConditionModal, setShowDeleteConditionModal] = useState(false);
    //publish
    const [publishConditionModal, setPublishConditionModal] = useState(false);
    const [passConditionId,setPassConditionId] = useState('');
    const [errorMessage,setErrorMessage] = useState('');
    const [showPopup,setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
   
    
    //Call back for refresh page
    const sendRefreshToParent = (tab)=>{
        onCallBackFromChild(tab);
    }
    const handleClose = () =>{ setFormData({
        programId: programId,
        conditionId: conditionId,
        conditionName: '',
        conditionDescription: '',
        pathwayName:'',
    }); 
    setErrors({
        programId: programId,
        conditionId: conditionId,
        conditionName: '',
        conditionDescription: '',
        
    })
    setShowEditConditionModal(false);
    setShowAddPathwayModal(false);
    setShowDeleteConditionModal(false);
    setPublishConditionModal(false);
    setShowPopup(false);
    setErrorMessage('');

   }

    const [formData, setFormData] = useState({
        programId: programId,
        conditionId: conditionId,
        conditionName: '',
        conditionDescription: '',
        pathwayName:'',
    })

    const [errors, setErrors] = useState({
        programId: programId,
        conditionId: conditionId,
        conditionName: '',
        conditionDescription: '',

    })

        //onchange
const onValueChange = (e) =>{    
    setFormData({...formData, [e.target.name]: e.target.value});
  }

    //open Edit condition
    const handleEditCondition = (conditionId) =>{
        editFetchNewCondition(conditionId)
       .then((response) => { 
           setFormData(response.data.data);
       });
       setShowEditConditionModal(true);
    }

    //submit edit program
const submitEditNewCondition = async (e) => {
    e.preventDefault();
    setErrors('')
    const validationErrors = validateFormNewConditionAdd(formData);
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
    }else{
      editNewCondition(formData)
        .then((response) => {   
            if(response.data.code == 200){
              setShowEditConditionModal(false);
              if(tab ===1){
                sendRefreshToParent(0);
              }else{
                sendRefreshToParent(1);
              }
            }else{
                setErrorMessage(response.data.data.message);
            }
        });
    }
  }

  //Add new pathway popup
const handleAddPathway = () =>{
    setFormData((prevFormData) => ({
        ...prevFormData,
        programId: programId, // replace `ProgramId` with the actual ID you want to set
        conditionId: conditionId,  // replace `conditionId` with the actual ID you want to set
    }));
    setShowAddPathwayModal(true);
}

//submit new pathway
const submitAddNewPathway = async (e) => {
  e.preventDefault();
  setErrors('')
  setIsLoading(true);
  const validationErrors = validateFormPathwayAdd(formData);
  if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
  }else{
    AddNewPathway(formData)
      .then((response) => {   
          if(response.data.code == 200){
            setShowAddPathwayModal(false);
            if(tab ===1){
                sendRefreshToParent(1);
              }else{
                sendRefreshToParent(1);
              }
              handleClose();
              setIsLoading(false);
          }
      });
  }
}



   //delete program
const handleDeleteNewCondition = () =>{
    setShowDeleteConditionModal(true);
}

//submit delete Condition
const submitDeleteNewCondition = async (e) => {
    e.preventDefault();
    setErrors('');
      removeNewCondition(conditionId)
        .then((response) => {   
            if(response.data.code == 200){
                setShowDeleteConditionModal(false);
                sendRefreshToParent(1);
            }
        });
  }

    //Handle public condition
const handlePublishNewCondition = (conditionId) =>{
    setPassConditionId(conditionId)
    displayMessageCondition(conditionId)
    .then((response) => {   
        if(response.data.code == 200){
            setShowPopup(true);
            setErrorMessage(response.data.data.message);
           
      }else{
            setShowPopup(false);
         setErrorMessage("Error Message");
         setPublishConditionModal(true); 
      }
    }).catch((error) => {
     // Handle any error that occurs during the API call
     console.error("Error during publishing condition:", error);
 });
}

const submitPublishNewCondition = () =>{
    publishNewCondition(passConditionId)
           .then((response) => {   
               if(response.data.code == 200){
                    sendRefreshToParent(0);
                    setPublishConditionModal(false);
             }else{
                setErrorMessage("Error Message")
             }
           }).catch((error) => {
            // Handle any error that occurs during the API call
            console.error("Error during publishing condition:", error);
        });
  }


return (
    <>
        <div className="DropDownAction">
            <div className="btn-group dropend" style={{ position: 'relative', zIndex: 0 }}>
                <button 
                    type="button" 
                    className="btn btn-light dropdown-toggle action-dropdown-btn" 
                    data-bs-toggle="dropdown" 
                    aria-expanded="false"
                >
                    <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu" style={{ zIndex: 2 }}>
                    {ifCheck === 1 && isApproved === 'N' ? (
                        <>
                            <li className="list-class">
                                <Link 
                                    to="" 
                                    className="dropdown-item" 
                                    onClick={(e) => handlePublishNewCondition(conditionId)}
                                    aria-label="Publish Condition"
                                    title="Publish Condition"
                                >
                                    <i className="bi bi-display me-2"></i> 
                                    Publish Condition
                                </Link>
                            </li>
                            <li className="list-class">
                                <Link 
                                    to="" 
                                    className="dropdown-item" 
                                    onClick={() => handleAddPathway()}
                                    aria-label="Add Pathway"
                                    title="Add Pathway"
                                >
                                    <i className="bi bi-plus-circle me-2"></i> 
                                    Add Pathway
                                </Link>
                            </li>
                            <li className="list-class">
                                <Link 
                                    to="" 
                                    className="dropdown-item" 
                                    onClick={(e) => handleEditCondition(conditionId)}
                                    aria-label="Edit Condition"
                                    title="Edit Condition"
                                >
                                    <i className="bi-pencil me-2"></i> 
                                    Edit Condition
                                </Link>
                            </li>
                            <li className="list-class">
                                <Link 
                                    to="" 
                                    className="dropdown-item" 
                                    onClick={() => handleDeleteNewCondition()}
                                    aria-label="Delete Condition"
                                    title="Delete Condition"
                                >
                                    <i className="bi bi-x-circle text-danger me-2"></i> 
                                    Delete Condition
                                </Link>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="list-class">
                                <Link 
                                    to="" 
                                    className="dropdown-item" 
                                    onClick={() => handleAddPathway()}
                                    aria-label="Add Pathway"
                                    title="Add Pathway"
                                >
                                    <i className="bi bi-plus-circle me-2"></i> 
                                    Add Pathway
                                </Link>
                            </li>
                            <li className="list-class">
                                <Link 
                                    to="" 
                                    className="dropdown-item" 
                                    onClick={(e) => handleEditCondition(conditionId)}
                                    aria-label="Edit Condition"
                                    title="Edit Condition"
                                >
                                    <i className="bi-pencil me-2"></i> 
                                    Edit Condition
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>

            <style jsx>{`
                .DropDownAction {
                    position: relative;
                    z-index: 1;
                }
                
                .dropdown-menu {
                    z-index: 1 !important;
                }
                
                .dropdown-menu.show {
                    z-index: 1 !important;
                }
                
                /* Ensure Bootstrap's default z-index doesn't override */
                .btn-group {
                    z-index: 1 !important;
                }
                
                /* Additional styling */
                .list-class .dropdown-item {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 1rem;
                }
                
                .list-class .dropdown-item i {
                    margin-right: 0.5rem;
                    font-size: 1rem;
                }
                
                .dropdown-item:hover {
                    background-color: #f8f9fa;
                }
            `}</style>
        </div>
        <Commingsoon />

        <div className={`modal fade ${showEditConditionModal ? 'show' : ''}`}  data-bs-backdrop="static" id="showEditConditionModalLabel" tabIndex="-1" aria-labelledby="showEditConditionModalLabel" aria-hidden={!showEditConditionModal} style={{display: showEditConditionModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">Edit Condition</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    {errorMessage ?(<p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>):null}
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={submitEditNewCondition}> 
                            <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='Condition' class="form-label">Condition Name</label>
                                        <span style={{color:'red'}}> *</span>
                                        <input type="text"
                                        className="form-control input-field-ui"
                                        placeholder="Enter condition name"
                                        name="conditionName"
                                        onChange={(e) => onValueChange(e)} value={formData['conditionName']}
                                        />
                                        {errors['conditionName'] && <div className="text-danger">{errors['conditionName']}</div>}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='conditionDescription' class="form-label">Description</label>
                                        <span style={{color:'red'}}> *</span>
                                        <textarea
                                        className="form-control input-field-ui"
                                        placeholder="Enter condition description"
                                        rows="3"
                                        name="conditionDescription"
                                        onChange={(e) => onValueChange(e)} value={formData['conditionDescription']}
                                        />
                                        {errors['conditionDescription'] && <div className="text-danger">{errors['conditionDescription']}</div>}
                                    </div>
                                   
                                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Save
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>

            <div className={`modal fade ${showAddPathwayModal ? 'show' : ''}`}  data-bs-backdrop="static" id="showAddPathwayModalLabel" tabIndex="-1" aria-labelledby="showAddPathwayModalLabel" aria-hidden={!showAddPathwayModal} style={{display: showAddPathwayModal ? 'block' : 'none'}}>
            <LoaderLoader isLoading={isLoading} />
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">Add New Pathway</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={submitAddNewPathway}> 
                            <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='pathwayName' class="form-label">Pathway Name</label>
                                        <span style={{color:'red'}}> *</span>
                                        <input type="text"
                                        className="form-control input-field-ui"
                                        placeholder="Enter pathway name"
                                        name="pathwayName"
                                        onChange={(e) => onValueChange(e)} value={formData['pathwayName']}
                                        />
                                        {errors['pathwayName'] && <div className="text-danger">{errors['pathwayName']}</div>}
                                    </div>
                                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Save
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>

            <div className={`modal fade ${showDeleteConditionModal ? 'show' : ''}`}  data-bs-backdrop="static" id="showDeleteConditionModalLabel" tabIndex="-1" aria-labelledby="showDeleteConditionModalLabel" aria-hidden={!showDeleteConditionModal} style={{display: showDeleteConditionModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* <p className="main-title">Are you sure want to delete this?</p> */}
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={submitDeleteNewCondition}> 
                            <div className="row">
                                    
                                   
                                    <div className="col-md-12 my-2 text-center">
                                    <div className="modal-body pt-1 mb-2 mx-auto w-80">
                                    Are you sure you want to delete it?</div>
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Confirm
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>

            <div className={`modal fade ${publishConditionModal ? 'show' : ''}`}  data-bs-backdrop="static" id="publishConditionModalLabel" tabIndex="-1" aria-labelledby="publishConditionModalLabel" aria-hidden={!publishConditionModal} style={{display: publishConditionModal ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* <p className="main-title">Are you sure want to publish condition?</p> */}
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div class="modal-body pt-1 mb-2">
                         
                            <div className="row">
                                    
                                   
                                    <div className="col-md-12 my-2 text-center">
                                    <div className="modal-body pt-1 mb-2 mx-auto w-80">Are you sure you want to publish the condition?</div>
                                        <button
                                        type="button"
                                        className="btn btn-primary program-save-btn me-2"
                                        onClick={submitPublishNewCondition}
                                        >
                                        Confirm
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                           
                        </div>
                    </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>
            <DisplayMessage showPopup={showPopup} 
        message={errorMessage} 
        onClose={handleClose}  />
    </>
  );
}

export default DropDownAction;

export const DisplayMessage = ({showPopup,message,onClose}) =>{
    return (
        <>
<div className={`modal fade ${showPopup ? 'show' : ''}`}  id="displayModalLabel"  data-bs-backdrop="static" tabIndex="-1" aria-labelledby="displayModalLabel" aria-hidden={!showPopup} style={{display: showPopup ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <p className="main-title">Are you sure want to publish condition?</p> */}
                            <button
                                type="button"
                                className="btn-close"
                                onClick={onClose}
                            ></button>
                        </div>
                        <div class="modal-body pt-1 mb-2 mx-auto w-80" style={{ color: '#000000' }}>
                            {message}
                        </div>
                           
                    </div>
                </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>
            </>
    );

}
