import React from 'react'

const PageDescription = ({image, text}) => {
  return (
    <div className="row align-items-center pb-3">
    <div className='col-12'>
    <div className='reports-programs'>
      <div className=" row align-items-center">
        <div className="col-md-auto">
                <img src={image} alt="Invited Members icon" style={{height:"140px"}}/>
         </div>
         <div className="col-md">
            <p className="mb-0">{text}</p>

            <div className='responsibilities'>
                <span className='mt-5'>Responsibilities: </span>

                <ul>  
                  <li>
                    <span>Business Team: Raise and confirm the observation is resolved.</span>
                  </li>

                  <li>
                    <span>Moderator: Review and approve/reject observations.</span>
                  </li>

                  <li>
                    <span>GOQii: Resolve the observations.</span>
                  </li>
                </ul>
            </div>
          </div>
        </div>
    </div>
    </div>
    
  </div>
  )
}

export default PageDescription; 
