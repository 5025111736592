import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './MemberServices.css';
import { fetchCallsHistory, updateTeleHealthService } from "../../../../services/cn/Service";
import { encryptDataEdge, ModalBoxButton } from '../../../utils/CommonLibrary';


const MemberCallHistory = forwardRef((props, ref) => {
    const qsParams = useParams();
    const userId = qsParams.memberId;

    const memberBasicInfo = props.memberBasicInfo ? props.memberBasicInfo : {};

    const [callsHistory, setCallsHistory] = useState([]);
    const [teleHealthHistory, setTeleHealthHistory] = useState([]);
    const [error, setError] = useState("");
    const [messageNew, setMessageNew] = useState("");    
    const [showModalIframe, setShowModalIframe] = useState(false);
    const [modalBodyIframe, setModalBodyIframe] = useState(false);
    const [fileUrlId, setFileUrlId] = useState('');
    const [fileUrlIdNew, setFileUrlIdNew] = useState("");
    const [modalTitle, setModalTitle] = useState('');
    const [modalClass, setModalClass] = useState('modal-fullscreen');
    const [showModalButton, setShowModalButton] = useState(false);
    const [callIdNew, setCallIdNew] = useState('');

    
    useEffect(() => {
        getCallsHistory();
    }, [userId])

    useImperativeHandle(ref, () => ({
        getCallsHistory
    }));

    const getCallsHistory = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchCallsHistory(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setCallsHistory(response.data.data.logs);
                    setTeleHealthHistory(response.data.data.teleService);
                    
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const openConfirmModal = async (bookingId,callId,date) => {
        setFileUrlId(callId);
        setCallIdNew(bookingId);
        setShowModalIframe(true);
        setModalTitle('Confirm!');
        setModalClass('md');
        setShowModalButton(true);
        setModalBodyIframe("Cancel TeleHealth consultation on " + date );

    }

    const cancelService = async () => {
        setFileUrlIdNew(fileUrlId);
        setShowModalIframe(false);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await updateTeleHealthService('cancel',callIdNew,fileUrlId,fileUrlId,fileUrlId, ct)
            .then((response) => {
                if (response.data.data.bookingData.status == "0") {
                    props.callCallsHistory();
                    setMessageNew(response.data.data.bookingData.bookingStatus);
                    setTimeout(() => setMessageNew(false), 2000);
                } else {
                    setError(response.data.data.bookingData.bookingStatus);
                    setFileUrlId("");
                    setFileUrlIdNew("");
                    setTimeout(() => setError(false), 2000);
                }
                
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const openMemberDetailsIframe = (membershipNo,isAramcoApi,appSourceApi,iframeUserApi,iframeUrlTemp,key) => {
        console.log('membershipNo',membershipNo)
        const memberNo = encryptDataEdge(membershipNo,key);
        const isAramco = encryptDataEdge(isAramcoApi,key);
        const appSource = encryptDataEdge(appSourceApi,key);
        const iframeUser = encryptDataEdge(iframeUserApi,key);
        const iframeUrlTempNew = iframeUrlTemp;
        const frameUrl = `${iframeUrlTempNew}memberNo=${memberNo};isAramco=${isAramco};appSource=${appSource};user=${iframeUser}`;
        // const frameUrl = `https://careconnectuat.bupa.com.sa/#/support-medical/main-member-medical-profile;memberNo=${memberNo};isAramco=${isAramco};appSource=${appSource};user=${iframeUser}`;
        
        setModalTitle("Member Details");
        setShowModalIframe(true);
        setModalClass('modal-fullscreen');
        setShowModalButton(false);
        setModalBodyIframe( <div className="iframe-container">
                                <iframe
                                    src={frameUrl}
                                    title="Member Details"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{ height:'75vh',width:'100%' }}
                                />
                            </div>
                        );
    }

    const handleCloseNew = () =>{
        setShowModalIframe(false);
        setFileUrlId("");
        setFileUrlIdNew("");
    }

    return (
        <>
            {
                callsHistory &&
                <div className='col-md-12 mt-3'>
                    <div className='card card-task'>
                        <div className='card-body'>
                            <div className='card-text'>
                                <div className='row align-items-center' >
                                    <div className='col'>
                                        <h5 className='card-title'>Calls History</h5>
                                        <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX:"hidden" }}>
                                            <table className="table table-striped table-bordered mb-0">
                                                <thead style={{ position: "sticky", top: "0" }}>
                                                    <tr>
                                                        <th scope="col">Date</th>
                                                        <th scope="col" style={{ width: "15%" }}>Call Type</th>
                                                        <th scope="col" style={{ width: "15%" }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        callsHistory.map((item, index) => {
                                                            return <>
                                                                <tr style={{ verticalAlign: 'middle' }}>
                                                                    <td className='bg-td-green'>{item.date}</td>
                                                                    <td className='bg-td-green'>{item.callType}</td>
                                                                    <td className='bg-td-green'>{item.callCurrentStatus}</td>
                                                                </tr>
                                                            </>
                                                        })  
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            
            {
                teleHealthHistory &&
                <div className='col-md-12 mt-3'>
                    <div className='card card-task'>
                        <div className='card-body'>
                            <div className='card-text'>
                                <div className='row align-items-center' >
                                    <div className='col'>
                                        <h5 className='card-title'>TeleHealth Consultation</h5>
                                        <div className='col-12'>
                                            <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                                            <div className={`${messageNew != "" ? "messageDivNew" : "hide"}`} style={{ fontWeight: "400 !important" }}>{messageNew}</div>
                                        </div>
                                        <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX:"hidden" }}>
                                            <table className="table table-striped table-bordered mb-0">
                                                <thead style={{ position: "sticky", top: "0" }}>
                                                    <tr>
                                                        <th scope="col">Date</th>
                                                        <th scope="col" style={{ width: "15%" }}>Doctor Name</th>
                                                        {/* <th scope="col" style={{ width: "15%" }}>Status</th> */}
                                                        <th scope="col" style={{ width: "15%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        teleHealthHistory.map((item, index) => {
                                                            return <>
                                                                <tr style={{ verticalAlign: 'middle' }} key={index}>
                                                                    <td className='bg-td-green'>{item.date}</td>
                                                                    <td className='bg-td-green'>{item.doctorName}</td>
                                                                    {/* <td className='bg-td-green'>{item.statusEdge}</td> */}
                                                                    <td className={`bg-td-green ${item.showAction == "1" ? "d-flex" : ""}`}>
                                                                        <>
                                                                            {(() => {
                                                                                if (item.showAction == "1") {
                                                                                    return (
                                                                                        <>
                                                                                            {item.showPopup == true && <button className="btn health-locker-btn me-2 " onClick={(e) => props.handleShowUploadTeleHealthReschedule(item.bookingId,item.callId)}><i className="bi bi-upload"></i></button> }
                                                                                            <button className="btn health-locker-btn me-2 " onClick={(e) => props.handleShowTeleHealthReschedule(item.bookingId,item.callId,item.doctorId,item.doctorName)}><i className="bi bi-calendar4-week"></i></button>
                                                                                            <button className={`btn health-locker-btn me-2 ${fileUrlId == item.callId ? "hide" : ""}`} onClick={(e) => openConfirmModal(item.bookingId,item.callId,item.date)}><i className="bi bi-x-lg"></i></button>

                                                                                            {
                                                                                                fileUrlIdNew == item.callId &&
                                                                                                <span><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "35px" }} alt="" /></span>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                } else if (item.showAction == "2") {
                                                                                    return (
                                                                                        <>
                                                                                        <button className="btn health-locker-btn me-2 " onClick={(e) => openMemberDetailsIframe(item.memberNo,item.isAramco,item.appSource,item.iframeUser,item.iframeUrl,item.k)} ><i className="bi bi-eye"></i></button>
                                                                                        </>
                                                                                    )
                                                                                } else {
                                                                                    return (<></>)
                                                                                }
                                                                            })()}
                                                                        </>
                                                                    </td>
                                                                </tr>   
                                                            </>
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showModalIframe ?<ModalBoxButton handleClose={handleCloseNew} showModaln={showModalIframe} titlen={modalTitle} bodyn={modalBodyIframe} button={showModalButton ? cancelService : undefined}  hideButton={handleCloseNew} type={modalClass} />:null}
            {/* {showModal ? <ModalBoxButton handleClose={handleClose} showModaln={showModal} titlen={modalTitle} bodyn={modalBody} type={modalClass} button={showModalButton ? handleButtonAction : undefined} hideButton={showHide} /> : null} */}
        </>
    )
});

export default MemberCallHistory